import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react';
import noImage from 'assets/no-image.png';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getMoreBestOfferInEachPlatform } from 'services/api/offer';
import { formatPrice } from 'utils';

import loadingIcon from '../../../assets/icons/loadingIcon.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import { PlatformIcon } from '../icon/PlatformIcon';

interface BestDealModalProps {
  className?: string;
  itemId: string;
  preview: string;
  collection_name: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function BestDealModal(props: BestDealModalProps) {
  const {
    isOpen,
    itemId,
    onClose = noop,
    preview,
    collection_name,
    onConfirm = noop
  } = props;

  const { isFetching, data } = useQuery(
    ['getMoreBestOfferInEachPlatform', itemId],
    () => getMoreBestOfferInEachPlatform(itemId),
    {
      refetchOnWindowFocus: false,
      retry: 10
    }
  );

  const useAble = useMemo(() => {
    return data?.data.filter((offer) => offer.usable);
  }, [data?.data]);

  return (
    <Modal size={'xl'} isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background={'#000000aa'} />

      <ModalContent
        color="white"
        alignItems={'center'}
        py={8}
        background={'#17171D'}
        borderRadius="20px"
        m={4}
      >
        <ModalCloseButton color="#49494B" m={2} size={'lg'} />
        <Stack
          px={8}
          w={'full'}
          flex={1}
          align={'center'}
          maxHeight={'80%'}
          overflowY="auto"
        >
          <Text
            pt={4}
            fontFamily="Space Grotesk"
            fontWeight="700"
            variant="title_3xl"
          >
            Best Deal
          </Text>

          <Box
            className="mb-[15px!important]"
            fontSize="16px"
            backgroundColor="#1C1C23"
            padding="20px"
            rounded="10px"
            width="full"
          >
            <Flex marginBottom="5" justifyContent="center" paddingY="20px">
              <AspectRatio
                rounded={'8px'}
                w="100px"
                overflow={'hidden'}
                ratio={1}
              >
                <>
                  {!preview && (
                    <Skeleton
                      fadeDuration={1}
                      startColor="#313136"
                      endColor="#373738"
                    >
                      <Image src={noImage} />
                    </Skeleton>
                  )}
                  {preview && <Image src={preview || noImage} />}
                </>
              </AspectRatio>

              <Box alignSelf="center" marginLeft="24px">
                <Text fontSize="20px">
                  {collection_name} #{itemId?.split('_')[1]}
                </Text>
                <Text color="whiteAlpha.800" fontSize="16px">
                  {collection_name}
                </Text>
              </Box>
            </Flex>

            <Text textAlign="center" fontSize="16px">
              Net income you’ll get across marketplaces
            </Text>
            {isFetching && (
              <Box className="absolute top-0 left-0 flex h-full min-h-[200px] w-full items-center justify-center">
                <Image
                  alignSelf="center"
                  className="animate-loadingData"
                  width="34px"
                  src={loadingIcon}
                />
              </Box>
            )}
            {!isFetching &&
              data?.data.map((offer, i) => {
                return (
                  <Flex
                    color={offer.usable ? '#ffffff' : '#47474D'}
                    key={i}
                    marginTop="10px"
                  >
                    <PlatformIcon size={24} type={offer.platform} />
                    <Text paddingLeft="10px">{offer.platform}</Text>

                    {!offer.usable && '*'}

                    <Box
                      marginX="10px"
                      width="full"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      flexGrow={1}
                    >
                      ................................................................................................................................................................................
                    </Box>
                    <Text whiteSpace="nowrap">
                      {offer.deductionPrice ? (
                        <>
                          {formatPrice(offer.deductionPrice)}
                          &nbsp; WETH
                        </>
                      ) : (
                        'Not Available'
                      )}
                    </Text>
                  </Flex>
                );
              })}

            <Text fontSize="16px" paddingY="14px" textColor="#47474D">
              * Collection Offers on OpenSea will be supported soon.
            </Text>
            <a
              target="_blank"
              href={`https://opensea.io/assets/${itemId.split('_').join('/')}`}
              rel="noreferrer"
            >
              View on Opensea <Icon as={ExternalLinkIcon}></Icon>
            </a>
          </Box>

          <Box paddingY="1" width="full" maxWidth={['full', '344px']}>
            <PrimaryButton
              disabled={useAble?.length === 0}
              title={'Proceed with the Highest Eligible Offer'}
              onClick={onConfirm}
            />
          </Box>
        </Stack>
      </ModalContent>
    </Modal>
  );
}

function noop() {
  //
}
