// import useScreen from "hooks/useScreen";
import { useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

type ParallexGroupProps = {
  zIndex?: string;
  isDesktop?: boolean;
} & React.ComponentProps<'div'>;

const ParallexGroupRoot = styled.div<ParallexGroupProps>`
  position: relative;
  min-height: ${(p) => (p.isDesktop ? '900px' : '1050px')};
  height: ${(p) => (p.isDesktop ? '100vh' : '100vh')};
  transform-style: preserve-3d;
  z-index: ${(p) => p.zIndex};
`;

const ParallexGroup = (props: ParallexGroupProps) => {
  // eslint-disable-next-line prefer-const
  let { isDesktop, ...otherProps } = props;
  const isDesktopUse = useBreakpointValue({ base: false, lg: true });
  isDesktop = isDesktopUse;

  return (
    <ParallexGroupRoot
      {...otherProps}
      isDesktop={isDesktop}
      className={``}
      role="group"
    >
      {props.children}
    </ParallexGroupRoot>
  );
};

export { ParallexGroup };
