import { UnsupportedChainIdError } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError
} from '@web3-react/injected-connector';
import BigNumber from 'bignumber.js';
import { utils } from 'ethers';
import { WEI_TO_ETH_RATIO, WEI_TO_GWEI_RATIO } from 'utils/const';

export const weiToETH = (value: number | string) => {
  return new BigNumber(value.toString()).dividedBy(WEI_TO_ETH_RATIO);
};

export const ethToWei = (value: number | string) => {
  return new BigNumber(value.toString()).multipliedBy(WEI_TO_ETH_RATIO);
};

export const weiToGWei = (value: number | string) => {
  return new BigNumber(value.toString()).dividedBy(WEI_TO_GWEI_RATIO);
};

export const toPercent = (point: number) => {
  let str = Number(point * 100).toFixed(2);
  str += '%';
  return str;
};

export const chunk = <T>(arr: T[], num: number) => {
  const number = num * 1 || 1;
  const ret: T[][] = [];

  arr.forEach(function (item, i) {
    if (i % number === 0) {
      ret.push([]);
    }
    ret[ret.length - 1].push(item);
  });

  return ret;
};

export function delayPromise(time: number) {
  return new Promise(function (resolve) {
    setTimeout(() => {
      resolve({
        status: ''
      });
    }, time);
  });
}

export function handleMetamaskError(error: Error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (error instanceof UserRejectedRequestError) {
    return 'Please authorize this website to access your Ethereum account.';
  } else if (error.message.includes('user rejected transaction')) {
    return 'user rejected transaction';
  } else {
    return error.message || 'An unknown error occurred. Please try again.';
  }
}

export function ellipsisAddress(address: string) {
  if (address) {
    return `${address.substr(0, 3)}...${address.substr(-4, 4)}`;
  }
  return address;
}

export function formatPrice(price: string) {
  const priceWithoutDot = price.split('.')[0];
  const etherPrice = Number(utils.formatEther(priceWithoutDot));
  if (etherPrice < 0.0001) return '< 0.0001';

  return parseFloat(etherPrice.toFixed(4));
}
