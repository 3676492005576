import axios from 'axios';
import { AssetsInfo, Item } from 'services/api/sell/model';
import { chunk } from 'utils';

import { getCenterApiKey, getReservoirsApiKey, reservoirApi } from '../util';

export async function getUserInventory(
  account: string | null | undefined,
  offset = 0
) {
  let tokens: Item[] = [];
  const response = await axios.get<{ tokens: Item[] }>(
    `${reservoirApi}/users/${account}/tokens/v5?sortDirection=desc&offset=${offset}&limit=100&includeTopBid=true`,
    {
      headers: {
        'x-api-key': getReservoirsApiKey()
      }
    }
  );

  tokens = response.data.tokens;

  if (response.data.tokens.length === 100) {
    const nextTokens = await getUserInventory(account, offset + 100);
    tokens = [...tokens, ...nextTokens];
  }

  return tokens;
}

export async function getAssetsDetail(
  assets: Array<{
    Address: string;
    TokenID: string;
  }>
) {
  const chunkAssets = chunk(assets, 100);

  const promises = chunkAssets.map(async (assets) => {
    const data = await axios.post<AssetsInfo[]>(
      `https://api.center.dev/v1/ethereum-mainnet/assets`,
      {
        assets
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      }
    );

    return data.data;
  });

  const data = await Promise.all(promises);
  return data.flat();
}

export async function getAssetsOwned(address: string): Promise<[AssetsInfo[], Item[]]> {
  const assetsRes = await getUserInventory(address);
  return [transferData(assetsRes) , assetsRes];
  // const assets = assetsRes.map((asset) => ({
  //   Address: asset.token.contract,
  //   TokenID: asset.token.tokenId
  // }));

  // use center api
  // const detailsRes = await getAssetsDetail(assets);

  // const detail = detailsRes
  //   .map((item, i) => {
  //     if (item?.collection_name)
  //       item.collection_name = assetsRes[i].token.collection.name;

  //     return item;
  //   })
  //   .filter((x) => x);

  // return detail;
}

function transferData(assets: Item[]) {
  return assets.map((item) => {
    return {
      loading: true,
      address: item.token.contract,
      collection_name: item.token.collection.name,
      token_id: item.token.tokenId,
      url: '',
      name: item.token.name,
      small_preview_image_url: item.token.image,
      acquired: item.ownership.acquiredAt,
      // tokenId: item.token.tokenId,
      // collectionName: item.token.collection.name,
      // smallPreviewImageUrl: item.token.image,
      // mediumPreviewImageUrl: item.token.image
    } as AssetsInfo;
  });
}
