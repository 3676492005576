import {
  Box,
  Center,
  chakra,
  Image,
  keyframes,
  shouldForwardProp,
  Text
} from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';
import { useState } from 'react';

import illustration1Black from '../../../assets/highswap-landing/illustration1_black.svg';
import illustration1Blue from '../../../assets/highswap-landing/illustration1_blue.svg';
import illustration2Black from '../../../assets/highswap-landing/illustration2_black.svg';
import illustration2Blue from '../../../assets/highswap-landing/illustration2_blue.svg';
import illustration3Black from '../../../assets/highswap-landing/illustration3_black.svg';
import illustration3Blue from '../../../assets/highswap-landing/illustration3_blue.svg';
import landingPageBackground from '../../../assets/highswap-landing/landing_page_background.svg';

function FeaturedGrid() {
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);

  const animationKeyframesGrid = keyframes`
  0% { clip-path: inset(18% 0 55% 0); transform: translate(-5px, 6px); }
  10% { clip-path: inset(45% 0 20% 0); transform: translate(-3px, 4px); }
  20% { clip-path: inset(28% 0 55% 0); transform: translate(5px, 5px); }
  30% { clip-path: inset(60% 0 5% 0); transform: translate(-6px, 2px); }
  40% { clip-path: inset(0 0 70% 0); transform: translate(-4px, -4px); }
  50% { clip-path: inset(50% 0 30% 0); transform: translate(-4px, -5px); }
  60% { clip-path: inset(80% 0 5% 0); transform: translate(5px, 4px); }
  70% { clip-path: inset(0 0 82% 0); transform: translate(4px, 5px); }
  80% { clip-path: inset(45% 0 30% 0); transform: translate(6px, 4px); }
  90% { clip-path: inset(18% 0 55% 0); transform: translate(4px, -6px); }
  100% { clip-path: inset(0 0 82% 0); transform: translate(5px, 8px); }`;

  const animationGrid = `${animationKeyframesGrid} 1s step-end`;
  const ChakraBox = chakra(motion.div, {
    shouldForwardProp: (prop) =>
      isValidMotionProp(prop) || shouldForwardProp(prop)
  });

  const handleMouseOver1 = () => {
    setIsHovering1(true);
  };

  const handleMouseOut1 = () => {
    setIsHovering1(false);
  };

  const handleMouseOver2 = () => {
    setIsHovering2(true);
  };

  const handleMouseOut2 = () => {
    setIsHovering2(false);
  };

  const handleMouseOver3 = () => {
    setIsHovering3(true);
  };

  const handleMouseOut3 = () => {
    setIsHovering3(false);
  };

  return (
    <>
      <Box
        background="#18171C"
        bgImage={landingPageBackground}
        bgPosition="top right"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          pl={{ base: '6', md: '18' }}
          pr={{ base: '6', md: '18' }}
          pb={50}
        >
          {/* <SimpleGrid
            columns={{ base: 1, sm: 1, md: 1, lg: 2, xl: 3 }}
            spacing={5}
            alignItems="center"
            justifyContent="center"
            color="#F7FBFE"
            background="#18171C"
          > */}
          <Box
            display={'flex'}
            flexDirection={{ base: 'column', xl: 'row' }}
            color="#F7FBFE"
            gap={'27px'}
            position={'relative'}
          >
            <Box position={'relative'}>
              <Box
                borderRadius="lg"
                bg={isHovering1 ? '#1649FF' : '#282735'}
                pl={12}
                pr={12}
                pt={10}
                pb={10}
                maxW="sm"
                position="absolute"
                onMouseOver={handleMouseOver1}
                onMouseOut={handleMouseOut1}
              >
                <Box fontSize="3xl" height="24">
                  Batch sell NFTs instantly
                </Box>
                <Box pt={5} fontSize="sm" height="32">
                  Tired of Jpegs? Get your exit liquidity in one click
                </Box>
                <Center top="0" height="48">
                  {!isHovering1 && <Image src={illustration1Blue} />}
                  {isHovering1 && <Image src={illustration1Black} />}
                </Center>
              </Box>
              <ChakraBox
                borderRadius="lg"
                bg={isHovering1 ? '#1649FF' : '#282735'}
                pl={12}
                pr={12}
                pt={10}
                pb={10}
                maxW="sm"
                animation={isHovering1 ? animationGrid : ''}
                pointerEvents="none"
              >
                <Text
                  fontSize="3xl"
                  height="24"
                  textShadow=" -2px 2px #6FFF6C, 2px -2px #C12828"
                >
                  Batch sell NFTs instantly
                </Text>
                <Text
                  pt={5}
                  fontSize="sm"
                  height="32"
                  textShadow=" -2px 2px #6FFF6C, 2px -2px #C12828"
                >
                  Tired of Jpegs? Get your exit liquidity in one click
                </Text>
                <Center top="0" height="48">
                  {!isHovering1 && <Image src={illustration1Blue} />}
                  {isHovering1 && <Image src={illustration1Black} />}
                </Center>
              </ChakraBox>
            </Box>

            <Box position={'relative'}>
              <Box
                borderRadius="lg"
                bg={isHovering2 ? '#1649FF' : '#282735'}
                pl={12}
                pr={12}
                pt={10}
                pb={10}
                maxW="sm"
                position="absolute"
                onMouseOver={handleMouseOver2}
                onMouseOut={handleMouseOut2}
              >
                <Box fontSize="3xl" height="24">
                  Save on gas and liquidator fees
                </Box>
                <Box pt={5} fontSize="sm" height="32">
                  Save up to 20% gas fee compared to selling to marketplaces
                  directly
                </Box>
                <Center top="0" height="48">
                  {!isHovering2 && <Image src={illustration2Blue} />}
                  {isHovering2 && <Image src={illustration2Black} />}
                </Center>
              </Box>
              <ChakraBox
                borderRadius="lg"
                bg={isHovering2 ? '#1649FF' : '#282735'}
                pl={12}
                pr={12}
                pt={10}
                pb={10}
                maxW="sm"
                animation={isHovering2 ? animationGrid : ''}
                pointerEvents="none"
              >
                <Text
                  fontSize="3xl"
                  height="24"
                  textShadow=" -2px 2px #6FFF6C, 2px -2px #C12828"
                >
                  Save on gas and liquidator fees
                </Text>
                <Text
                  pt={5}
                  fontSize="sm"
                  height="32"
                  textShadow=" -2px 2px #6FFF6C, 2px -2px #C12828"
                >
                  Save up to 20% gas fee compared to selling to marketplaces
                  directly
                </Text>
                <Center top="0" height="48">
                  {!isHovering2 && <Image src={illustration2Blue} />}
                  {isHovering2 && <Image src={illustration2Black} />}
                </Center>
              </ChakraBox>
            </Box>

            <Box position={'relative'}>
              <Box
                borderRadius="lg"
                bg={isHovering3 ? '#1649FF' : '#282735'}
                pl={12}
                pr={12}
                pt={10}
                pb={10}
                maxW="sm"
                position="absolute"
                onMouseOver={handleMouseOver3}
                onMouseOut={handleMouseOut3}
              >
                <Box fontSize="3xl" height={{ base: '32', md: '24' }}>
                  Real-time offers across marketplaces
                </Box>
                <Box pt={5} fontSize="sm" height="32">
                  Check the lastest offer in one interface
                </Box>
                <Center top="0" height="48">
                  {!isHovering3 && <Image src={illustration3Blue} />}
                  {isHovering3 && <Image src={illustration3Black} />}
                </Center>
              </Box>
              <ChakraBox
                borderRadius="lg"
                bg={isHovering3 ? '#1649FF' : '#282735'}
                pl={12}
                pr={12}
                pt={10}
                pb={10}
                maxW="sm"
                animation={isHovering3 ? animationGrid : ''}
                pointerEvents="none"
              >
                <Text
                  fontSize="3xl"
                  height={{ base: '32', md: '24' }}
                  textShadow=" -2px 2px #6FFF6C, 2px -2px #C12828"
                >
                  Real-time offers across marketplaces
                </Text>
                <Text
                  pt={5}
                  fontSize="sm"
                  height="32"
                  textShadow=" -2px 2px #6FFF6C, 2px -2px #C12828"
                >
                  Check the lastest offer in one interface
                </Text>
                <Center top="0" height="48">
                  {!isHovering3 && <Image src={illustration3Blue} />}
                  {isHovering3 && <Image src={illustration3Black} />}
                </Center>
              </ChakraBox>
            </Box>
          </Box>
          {/* </SimpleGrid> */}
        </Box>
      </Box>
    </>
  );
}

export default FeaturedGrid;
