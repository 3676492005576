import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllActionsOf } from 'redux_store/utils/Actions';

export const initialState = {
  ETHPrice: 0
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    update(state, action: PayloadAction<number>) {
      state.ETHPrice = action.payload;
    }
  }
});

const { actions, reducer } = slice;

export type WalletActionsType = AllActionsOf<typeof actions>;
export { actions as walletActions, reducer as walletReducer };
