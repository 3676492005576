import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllActionsOf } from 'redux_store/utils/Actions';

import { NftInTransaction, SellingCartItem, SellingCartState } from './model';

export const initialState: SellingCartState = {
  isOpenSellCard: true,
  trading: false,
  sellingCartItems: [],
  nftsInTransaction: []
};

const slice = createSlice({
  name: 'sellingCart',
  initialState,
  reducers: {
    updateIsOpenSellCard(state, action: PayloadAction<boolean>) {
      state.isOpenSellCard = action.payload;
    },

    // TODO: 定义模糊
    updateTransactionStatus(state, action: PayloadAction<NftInTransaction>) {
      const updateData = state.nftsInTransaction.map((tx) => {
        if (tx.hash === action.payload.hash) {
          return action.payload;
        }
        return tx;
      });

      state.nftsInTransaction = updateData;
    },
    addTransaction(state, action: PayloadAction<NftInTransaction>) {
      state.nftsInTransaction.push(action.payload);
    },
    updateTransaction(state, action: PayloadAction<NftInTransaction[]>) {
      state.nftsInTransaction = action.payload;
    },

    updateTradingStatus(state, action: PayloadAction<boolean>) {
      state.trading = action.payload;
    },
    update(state, action: PayloadAction<SellingCartItem[]>) {
      if (state.trading) return;
      state.sellingCartItems = action.payload;
    },
    add(state, action: PayloadAction<SellingCartItem>) {
      if (state.trading) return;
      state.sellingCartItems.push(action.payload);
    },
    delete(state, action: PayloadAction<string>) {
      if (state.trading) return;
      state.sellingCartItems = state.sellingCartItems.filter(
        (item) => item.itemId !== action.payload
      );
    }
  }
});

const { actions, reducer } = slice;

export type SellingCartActionsType = AllActionsOf<typeof actions>;
export { actions as sellingCartActions, reducer as sellingCartReducer };
