import {
  Box,
  Image,
  InputLeftElement,
  useBreakpointValue
} from '@chakra-ui/react';
import CustomInput from 'components/elements/input/CustomInput';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchActions } from 'redux_store/search/slice';

export default function SearchSection() {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      dispatch(searchActions.update(value));
    }
  };

  const handleClick = useCallback(() => {
    dispatch(searchActions.update(value));
  }, [dispatch, value]);

  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Box
      onKeyDown={handleKeyPress}
      position={'relative'}
      w={isDesktop ? '340px' : 'full'}
    >
      <CustomInput
        placeHolderColor="rgba(173, 173, 177, 0.8)"
        borderColor="#404045"
        placeholder="Search for NFTs by name, #ID..."
        rightElement={
          <InputLeftElement alignItems={'center'} pt={2} pl={2}>
            <Image
              w="24px"
              h="24px"
              cursor="pointer"
              src={require('assets/icons/Search.png')}
              onClick={handleClick}
            />
          </InputLeftElement>
        }
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {/*  {value && showResult && (
        <Box
          top={'60px'}
          w={'340px'}
          left={isDesktop ? 0 : '-75px'}
          position={'absolute'}
          background={'white'}
          boxShadow={'2xl'}
          borderRadius={'16px'}
          zIndex={100}
          p={6}
        >
          {isLoading ? (
            <Spinner size={'md'} color="purple.500" />
          ) : (
            <Box>
              <Text color={'black'} variant="title_l">
                NFTs
              </Text>
              {nfts.length > 0 ? (
                <Stack>
                  {nfts.slice(0, 4).map((n) => {
                    return (
                      <Stack
                        key={n.id}
                        onClick={() => {
                          navigate(
                            `/collections/${
                              n.contract?.slug ?? 'test-azuki'
                            }/nfts/${n.token_id}`
                          );
                          setShowResult(false);
                        }}
                        _hover={{ background: 'gray.100' }}
                        alignItems={'center'}
                        cursor={'pointer'}
                        py={2}
                        px={2}
                        direction={'row'}
                      >
                        <Image
                          src={n.art_url}
                          w={'32px'}
                          h={'32px'}
                          borderRadius={'6px'}
                        />
                        <Text
                          color={'black'}
                          variant="title_l"
                          fontWeight={'bold'}
                          pl={4}
                        >{`${n.name} #${n.token_id}`}</Text>
                      </Stack>
                    );
                  })}
                  <SecondaryButton
                    onClick={() => {
                      navigate(`/search?keyword=${value}`);
                      setShowResult(false);
                    }}
                    title="See All"
                  />
                </Stack>
              ) : (
                <Text variant="caption">No Nfts found</Text>
              )}
            </Box>
          )}
        </Box>
      )} */}
    </Box>
  );
}
