import { userActions } from 'redux_store/user/slice';
import * as Effects from 'redux-saga/effects';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from 'services/api/httpClient';
import { Nft, OrderResponse } from 'services/api/nft/model';
import {
  getUserFavorite,
  getUserOrders,
  getUserProfile
} from 'services/api/user';
import { User } from 'services/api/user/model';
import { destroyToken } from 'services/api/util';

const call: any = Effects.call;

function* handleGetUserProfile() {
  try {
    const data: User = yield call(getUserProfile);
    yield put(userActions.getUserProfileSuccess(data));
    yield put(userActions.getUserItems());
    yield put(userActions.getUserFavorite());
  } catch (e: any) {
    yield put(
      userActions.getUserProfileFailed({
        shouldLogout: !!(
          e.response.status === HttpStatus.Forbidden ||
          e.response.status === HttpStatus.Unauthorized
        )
      })
    );

    console.log(e);
  }
}

function* handleLogout() {
  try {
    yield call(destroyToken);
  } catch (e: any) {
    console.log(e);
  }
}

function* handleGetUserItems() {
  try {
    const orders: OrderResponse[] = yield call(getUserOrders);
    const listedOrders = orders.filter(
      (n) => n.status === 600 || n.status === 700
    );
    const validOrders = orders.filter(
      (n) => n.status !== 100 && n.status !== 600 && n.status !== 700
    );
    yield put(
      userActions.getOwnedItemsSuccess(
        validOrders.filter((n) => n.status !== 300)
      )
    );

    yield put(userActions.getListedItemsSuccess(listedOrders));
    yield put(
      userActions.getInProgressItemsSuccess(
        validOrders.filter((n) => n.status === 300)
      )
    );
  } catch (e: any) {
    console.log(e);
    yield put(userActions.getUserFailed());
  }
}

function* handleGetUserFavorites() {
  try {
    const favorites: Nft[] = yield call(getUserFavorite);
    yield put(userActions.getUserFavoriteSuccess(favorites));
  } catch (e: any) {
    console.log(e);
  }
}

function* watchGetUserProfile() {
  yield takeLatest(userActions.getUserProfile.type, handleGetUserProfile);
}

function* watchLogout() {
  yield takeLatest(userActions.logout.type, handleLogout);
}

function* watchGetUserItems() {
  yield takeLatest(userActions.getUserItems.type, handleGetUserItems);
}

function* watchGetUserFavorites() {
  yield takeLatest(userActions.getUserFavorite.type, handleGetUserFavorites);
}

const saga = [
  fork(watchGetUserProfile),
  fork(watchLogout),
  fork(watchGetUserItems),
  fork(watchGetUserFavorites)
];

export default saga;
