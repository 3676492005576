import {
  Box,
  Flex,
  Image,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SearchSection, UserWallet } from 'components/elements';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'redux_store/rootReducer';
import { sellingCartActions } from 'redux_store/sellingCart/slice';

import { CartIcon } from '../icon/CartIcon';

const ColorfulBorder = styled.div`
  width: 100%;
  height: 1px;
  bottom: 0;
  background: #404045;
`;

const NavBar = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const dispatch = useDispatch();
  const { isOpenSellCard } = useSelector((s: RootState) => s.sellingCart);

  const updateIsOpen = useCallback(() => {
    dispatch(sellingCartActions.updateIsOpenSellCard(!isOpenSellCard));
  }, [dispatch, isOpenSellCard]);

  return (
    <Box
      as="nav"
      position={'fixed'}
      top={0}
      bgColor={'#212124'}
      transition="all 0.2s ease"
      w={'full'}
      h={'86px'}
      zIndex={120}
    >
      <Stack
        className="h-[86px] px-2 py-4 md:px-6"
        direction={'row'}
        align={'center'}
      >
        <Box animation={'fadeInUpBig 1s ease'}>
          <Link to="/" className="shrink-0">
            <Flex className="ml-1 md:ml-0">
              <Image src={require('assets/headerLogo@2x.png')} w="47px" />
              <Text
                color="#ffffff"
                alignSelf="center"
                fontSize="24px"
                paddingLeft="9px"
              >
                HIGHSWAP
              </Text>
            </Flex>
          </Link>
        </Box>
        {!isDesktop && <Box flex={1} />}

        <Stack
          spacing={6}
          direction={'row'}
          align={'center'}
          w="full"
          pr={0}
          pl={['0', '0', '24']}
        >
          {isDesktop && (
            <>
              <Box>
                <SearchSection />
              </Box>
              <Box flex={1} />
            </>
          )}

          <Flex flexGrow={1} justifyContent="flex-end">
            <UserWallet />
          </Flex>

          {/* {isDesktop && ( */}
          <Stack
            marginLeft={isDesktop ? '' : '10px!important'}
            className="cursor-pointer pr-0 md:pr-4"
            onClick={updateIsOpen}
          >
            <CartIcon color="#ADADB1" fontSize={isDesktop ? '34px' : '25px'} />
          </Stack>
          {/* )} */}
        </Stack>
      </Stack>
      <ColorfulBorder />
    </Box>
  );
};

export default NavBar;
