import {
  Box,
  Checkbox, 
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Switch,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import CustomInput from 'components/elements/input/CustomInput';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux_store/rootReducer';
import { searchActions } from 'redux_store/search/slice';
import { filterActions } from 'redux_store/filter/slice';
import expand from '../../../assets/icons/expand.png';


export default function FilterSection({filterShow}: {filterShow: Boolean}) {

  type Props = {
    options: string[],
    selectedOptions: string[],
    onChange: (selected: string[]) => void
  }

  const [value, setValue] = useState<string>('');
  const [min, setMin] = useState<string>('');
  const [max, setMax] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [collectionArray, setCollectionArray] = useState<string[]>([]);
  const [collectionSets, setCollectionSet] = useState<string[]>([]);
  const [platformsArray, setPlatformArray] = useState<string[]>([]);
  const [toggle, setToggle] = useState<{ [key: string]: boolean }>({
    price: true,
    category: true,
    collection:true,
    platforms:true,
  });

  const { nftListLoaded, inventory, collectionSet, collection, platforms } = useSelector(
    (s: RootState) => s.nftList
  );
  
  

  const { collectionFilter, platformFilter, filterOffer, maxprice, minprice } = useSelector(
    (s: RootState) => s.filter
  );

  useEffect(() => {
    const collectionarray = Array.from(collection);
    const collectionSetArray = Array.from(collectionSet);
    setCollectionArray(collectionarray);
    const platformSet = new Set(platforms)
    const platformArray = Array.from(platformSet);
    setPlatformArray(platformArray);
  }, []);


  const updateMin = (value: string) => {
    dispatch(filterActions.updateMin(parseFloat(value) * 1000000000000000000));
  };

  const updateMax = (value: string) => {
    dispatch(filterActions.updateMax(parseFloat(value) * 1000000000000000000));
  };

  const CheckboxList = ({ options, selectedOptions, onChange }: Props) => {
    return (
      <CheckboxGroup value={Array.from(selectedOptions)} onChange={onChange}>
        <Flex flexDirection='column'>
          {Array.from(options).map((option) => (
            <Checkbox key={option} value={option} marginTop="12px">
              {option}
            </Checkbox>
          ))}
        </Flex>
      </CheckboxGroup>
    );
  };
  const handleCollectionFilter = (selected: string[]) => {
    dispatch(filterActions.updateCollectionFilter(selected));
  };
  const handlePlatformFilter = (selected: string[]) => {
    dispatch(filterActions.updatePlatformFilter(selected));
  };
  
  const toggleShow = (keyword: string) => {
    setToggle(prevState => ({
      ...prevState,
      [keyword]: !prevState[keyword],
    }));
  };

  const dispatch = useDispatch();

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      dispatch(searchActions.update(value));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(filterActions.updateOfferFilter(event.target.checked));
    setIsChecked(event.target.checked);
  };


  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <Box
      position={'relative'}
      w={isDesktop ? (filterShow ? '250px' : 'full') : (filterShow ? '250px' : '0px')}

      flexShrink="0"
      transition='width 1s ease-out'
    >
      <FormControl display='flex' alignItems='center' justifyContent='space-between'>
        <FormLabel htmlFor='filterOffer' mb='0' className='text-[16px] text-white'>
        Has offers
        </FormLabel>
        <Switch 
          onChange={handleChange}
          isChecked={filterOffer}
          colorScheme='messenger' 
          id='filterOffer' 
        />
      </FormControl>
      <Flex justifyContent='space-between' marginTop='27px' className='text-[16px] text-white'>
        <Text>Price</Text>
        <Image
          w="24px"
          h="24px"
          cursor="pointer"
          src={expand}
          onClick={() => toggleShow('price')}
          className={toggle['price'] ? '' : 'rotate-180'}
        />
      </Flex>
      {toggle['price'] && 
        <Flex justifyContent='space-between' alignItems='center' marginTop='13.5px'>
          <CustomInput
            placeHolderColor="rgba(173, 173, 177, 0.8)"
            borderColor="#404045"
            placeholder="min"
            onChange={(e) => {
              updateMin(e.target.value);
            }}
          />
          <Text fontSize='16px' margin='0 10px'>to</Text>
          <CustomInput
            placeHolderColor="rgba(173, 173, 177, 0.8)"
            borderColor="#404045"
            placeholder="max"
            onChange={(e) => {
              updateMax(e.target.value);
            }}
          />
        </Flex>
      }
      <Flex justifyContent='space-between' marginTop='27px' className='text-[16px] text-white'>
        <Text>Category</Text>
        <Image
          w="24px"
          h="24px"
          cursor="pointer"
          src={expand}
          onClick={() => toggleShow('category')}
          className={toggle['category'] ? '' : 'rotate-180'}
        />
      </Flex>
      <Flex justifyContent='space-between' marginTop='27px' className='text-[16px] text-white'>
        <Text>Collection</Text>
        <Image
          w="24px"
          h="24px"
          cursor="pointer"
          src={expand}
          onClick={() => toggleShow('collection')}
          className={toggle['collection'] ? '' : 'rotate-180'}
        />
      </Flex>
      {toggle['collection'] && 
        <Flex>
          <CheckboxList options={collectionArray} selectedOptions={collectionFilter} onChange={handleCollectionFilter}/>
        </Flex>
      }
      <Flex justifyContent='space-between' marginTop='27px' className='text-[16px] text-white'>
        <Text>Platforms</Text>
        <Image
          w="24px"
          h="24px"
          cursor="pointer"
          src={expand}
          onClick={() => toggleShow('platforms')}
          className={toggle['platforms'] ? '' : 'rotate-180'}
        />
      </Flex>
      {toggle['platforms'] && 
        <Flex>
          <CheckboxList options={platformsArray} selectedOptions={platformFilter} onChange={handlePlatformFilter} />
        </Flex>
      }
    </Box>
  );
}
