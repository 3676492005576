import { Box, Button, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux_store/rootReducer';
import { paginationActions } from 'redux_store/pagination/slice';

export default function PaginationSection() {
  const dispatch = useDispatch();
  const { currentPage, pageSize, totalItems } = useSelector(
    (state: RootState) => state.pagination
  );
  const totalPages = Math.ceil(totalItems / pageSize);

  const [pageSizeInput, setPageSizeInput] = useState(pageSize);

  const handlePrevPage = () => {
    dispatch(paginationActions.prevPage());
  };

  const handleNextPage = () => {
    dispatch(paginationActions.nextPage());
  };

  const handleSetPage = (page: number) => {
    dispatch(paginationActions.setPage(page));
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSize = Number(e.target.value);
    if (!isNaN(newSize) && newSize > 0) {
      dispatch(paginationActions.setPageSize(newSize));
      setPageSizeInput(newSize);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Button disabled={currentPage <= 0} onClick={handlePrevPage}>
        Previous
      </Button>
      {Array.from({ length: totalPages }, (_, i) => (
        <Box
          as="button"
          mx={2}
          p={2}
          bg={currentPage === i ? '#164AFF' : 'gray.300'}
          color={"#fff"}
          borderRadius={"6px"}
          onClick={() => handleSetPage(i)}
          cursor="pointer"
        >
          {i + 1}
        </Box>
      ))}
      <Button
        disabled={currentPage >= totalPages - 1}
        onClick={handleNextPage}
      >
        Next
      </Button>
      {/* <Box ml={5} display="flex" alignItems="center">
        <Text 
          mr={2}
          color={"#fff"}>
            Items per page:
        </Text>
        <Input
          w={20}
          type="number"
          color={"#fff"}
          min={1}
          value={pageSizeInput}
          onChange={handlePageSizeChange}
          placeholder="Page size"
        />
      </Box> */}
    </Box>
  );
}
