import {
  Box,
  Flex,
  Text,
  Image,
  // useInterval,
  useToast,
  Table, Thead, Tbody, Tr, Th, Td
} from '@chakra-ui/react';

import { useWeb3React } from '@web3-react/core';

import { SellingCart } from 'components/elements/sellingCart/card';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux_store/rootReducer';
import { SellingCartItem } from 'redux_store/sellingCart/model';
import { sellingCartActions } from 'redux_store/sellingCart/slice';
import { detailActions } from 'redux_store/detail/slice';
import { AssetsInfo, Collection } from 'services/api/sell/model';
import { formatPrice } from 'utils';

import { PlatformIcon } from '../elements/icon/PlatformIcon';
import expand from '../../assets/icons/expand.png';
import verified from '../../assets/icons/verified.png';

import bolt from '../../assets/icons/bolt.png';
import sell from '../../assets/icons/sell.png';
import WETHIcon from '../../assets/icons/eth_icon.png';

import etherscan from '../../assets/icons/etherscan.png';
import open from '../../assets/icons/open.png';

export default function Detail() {
  const toast = useToast();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const [walletAddress, setWalletAddress] = useState<
    string | null | undefined
  >();
  const [item, setItem] = useState<AssetsInfo>();
  const [collectionObj, setCollection] = useState<Collection>();
  const [showCart, setAddCart] = useState<Boolean>(false);


  const { collection, itemid } = useParams();
  const [refresh, setRefresh] = useState(0);


  const { sellingCartItems, isOpenSellCard } = useSelector(
    (s: RootState) => s.sellingCart
  );



  const { collectionSet, inventoryMerge } = useSelector(
    (s: RootState) => s.nftList
  );



  const { detail } = useSelector(
    (s: RootState) => s.detail
  );


  useEffect(() => {
    const key = `${itemid}${collection}`;
    const detailInfo = detail[key];
    if (detailInfo) {
      setCollection(detailInfo?.collection)
      setItem(detailInfo?.assetsInfo)
    }

  }, []);


  useEffect(() => {
    const key = `${itemid}${collection}`;
    if (inventoryMerge) {
      const inventoryArray = inventoryMerge as AssetsInfo[];
      const object = inventoryArray.find((item) => item?.token_id === itemid && item.address === collection);
      if (collectionSet instanceof Map) {
        const collectionObject = collectionSet.get(collection!);

        if (collectionObject && object) {
          setCollection(collectionObject);
          setItem(object);
          dispatch(detailActions.addDetail({ key, collectionObject, object }));
        }
      }
    }

  }, [inventoryMerge]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleShow = () => {
    const showCartStatus = showCart;
    setAddCart(!showCartStatus);
  };


  const toggle = useCallback(
    (nftItem: SellingCartItem) => {
      for (const item of sellingCartItems) {
        if (item.itemId === nftItem.itemId) {
          return;
        }
      }
      if (sellingCartItems.length >= 1) {
        toast({
          title: 'Error',
          description: 'Exceeding the maximum sale limit',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
        return;
      }
      console.log('nftItem ', nftItem)
      dispatch(sellingCartActions.add(nftItem));
      dispatch(sellingCartActions.updateIsOpenSellCard(true));
    },
    [dispatch, sellingCartItems.length, toast]
  );

  return (
    <>
      <Box w="full">
        <Box
          w={'full'}
          minHeight="calc(100vh - 232px)"
          className="relative flex justify-between"
        >
          <Box
            overflow="auto"
            className="relative w-full border-r-[1px] border-[#404045] px-[66px] md:px-10 py-10"
          >
            <Flex my={"27px"} onClick={handleGoBack} cursor="pointer">
              <Image
                w="24px"
                h="24px"
                marginRight="14px"
                cursor="pointer"
                src={expand}
                transform="rotate(270deg)"
                opacity={"0.6"}
              />
              <Text color="#ADADB1">Go Back</Text>
            </Flex>
            <Flex gap="40px" marginBottom={"64px"}>
              <Box borderRadius="10px">
                <Image
                  w="384px"
                  h="384px"
                  m="0 auto"
                  borderRadius="10px"
                  cursor="pointer"
                  src={item?.small_preview_image_url}
                />
              </Box>
              <Box flexGrow="1">
                <Flex
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  marginBottom="16px"
                >
                  <Text fontSize={"32px"} color="#fff">{item?.name}</Text>
                  <Image
                    w="24px"
                    h="24px"
                    m="0 10px"
                    borderRadius="10px"
                    cursor="pointer"
                    src={verified}
                  />
                </Flex>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Flex
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    <Image
                      w="32px"
                      h="32px"
                      borderRadius="32px"
                      marginRight="12px"
                      src={collectionObj?.imageUrl}
                    />

                    <Text fontSize={"16px"} color="#fff">{item?.collection_name}</Text>
                  </Flex>
                  <Flex
                    justifyContent={"flex-start"}
                    flexDir={"column"}
                    textAlign={"left"}
                  >

                    <Text fontSize={"14px"} color="#ADADB1">Floor price</Text>

                    <Text fontSize={"12px"} color="#fff">{collectionObj?.floorAskPrice}</Text>
                  </Flex>
                </Flex>
                <Box my={"20px"} height={"1px"} width="100%" backgroundColor="#49494B"></Box>
                <Flex marginBottom="75px">

                  <a target="_blank" href={`https://etherscan.io/address/${collection}`}>
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Image
                        marginX="3px"
                        alignSelf="center"
                        width="20px"
                        height="20px"
                        src={etherscan}
                      ></Image>
                      <Text color="#fff">View on Etherscan</Text>
                      <Image
                        marginX="3px"
                        alignSelf="center"
                        width="16px"
                        height="16px"
                        src={open}
                      ></Image>
                    </Flex>
                  </a>
                </Flex>
                <Flex
                  w="213px"
                  h="48px"
                  marginBottom="12px"
                  px="16px"
                  color="#fff"
                  borderRadius={"8px"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  backgroundColor={"#1C1C23"}
                >
                  <Text>Best Offer  </Text>
                  <Image
                    marginX="3px"
                    alignSelf="center"
                    width="32px"
                    height="32px"
                    src={WETHIcon}
                  ></Image>
                  <Text fontSize="20px">{item?.offer?.price && formatPrice(item.offer.price)}</Text>
                </Flex>
                {sellingCartItems.length === 0 &&
                  <Flex
                    w="213px"
                    h="48px"
                    backgroundColor={"#164AFF"}
                    borderRadius={"8px"}
                    color="#fff"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Image
                      h="24px"
                      m="0 8px 0 20px"
                      borderRadius="10px"
                      cursor="pointer"
                      src={bolt}
                    />
                    <Text>Sell instantly</Text>
                    <Box w="1px" height="100%" border="1px solid rgba(255, 255, 255, 0.15);"></Box>
                    <Image
                      w="24px"
                      h="24px"
                      marginRight="14px"
                      cursor="pointer"
                      src={expand}
                      onClick={() => toggleShow()}
                      className={'rotate-180'}
                    />
                  </Flex>
                }
                {(showCart && sellingCartItems.length === 0) &&
                  <Flex
                    w="213px"
                    h="48px"
                    paddingLeft="24px"
                    paddingRight="43px"
                    backgroundColor={"#1C1C23"}
                    borderRadius={"8px"}
                    cursor={"pointer"}
                    color="#fff"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    onClick={() => toggle({
                      itemId: `${item?.address}_${item?.token_id}`,
                      preview: item?.small_preview_image_url!,
                      collection_name: item?.collection_name!,
                      price: item?.offer?.price!,
                      platform: item?.offer?.platform!,
                      creatorFee: item?.offer?.creatorFee!,
                      platformFee: item?.offer?.platformFee!,
                      deductionPrice: item?.offer?.deductionPrice!,
                      hash: item?.offer?.hash!,
                      type: item?.address!,
                      protocolAddress: item?.offer.protocolAddress!,
                      maker: item?.offer.maker!,
                    })}
                  >
                    <Image
                      h="24px"
                      borderRadius="10px"
                      cursor="pointer"
                      src={sell}
                    />
                    <Text>Add to cart</Text>
                  </Flex>
                }
              </Box>
            </Flex>
            <Box>
              <Table variant="simple" backgroundColor={"#1C1C23"} borderRadius={"12px"}>
                <Thead>
                  <Tr>
                    <Th>MARKETPLACE</Th>
                    <Th>OFFER PRICE</Th>
                    <Th>PLATFORM FEE</Th>
                    <Th>CREATOR FEE</Th>
                    <Th>NET INCOME</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {item?.availableOffers?.map((item) => (
                    <Tr border="none" color="#fff" key={item.id}>
                      <Td border="none" align="center"><PlatformIcon size={[20, 20]} type={item?.platform} />  {`  ${item.platform}`}</Td>
                      <Td border="none" align="center">{formatPrice(item.price)}</Td>
                      <Td border="none" align="center">{item.deductionPrice}</Td>
                      <Td border="none" align="center">{item.creatorFee}</Td>
                      <Td border="none" align="center">{formatPrice(item.price)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

            </Box>
          </Box>
          <Box
            hidden={!isOpenSellCard}
            position={['fixed', 'fixed', 'relative']}
            flexShrink={0}
            width={['full', 'full', '370px', '410px', '450px', '500px']}
            bottom={['0', '0', 'unset']}
            zIndex={10}
          >
            <Box
              className="px-[16px] pt-[16px] lg:block lg:px-[18px] lg:pt-[18px]"
              position={['absolute', 'absolute', 'fixed']}
              width={['full', 'full', '370px', '410px', '450px', '500px']}
              bottom={['14px', '14px', 'unset']}
            >
              <SellingCart
                onRefresh={() => {
                  setTimeout(() => {
                    setRefresh(Math.random());
                  }, 2000);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
