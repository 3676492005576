import styled from '@emotion/styled';
import React from 'react';

type ParallexProps = React.ComponentProps<'div'>;

const ParallexRoot = styled.div`
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Parallex = React.forwardRef((props: ParallexProps, ref: any) => {
  return <ParallexRoot ref={ref}>{props.children}</ParallexRoot>;
});

Parallex.displayName = 'Parallex';

export { Parallex };
