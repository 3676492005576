import {
  Box,
  Button,
  keyframes,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import React from 'react';

export interface SecondaryButtonProps {
  onClick?: (e: any) => void;
  disabled?: boolean;
  title?: string;
  leftIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  rightIcon?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  isLoading?: boolean;
  arrow?: boolean;
  size?: string;
}
const borderWidth = 2;
const ButtonBorderSwirl = keyframes`
0% { transform: translate(-2px,calc(-50% +  ${
  borderWidth + 26
}px)) rotate(0deg) ; }
100% { transform: translate(-2px,calc(-50% +  ${
  borderWidth + 26
}px)) rotate(270deg); }
}`;
export default function SecondaryButton({
  onClick,
  title,
  disabled,
  leftIcon,
  rightIcon,
  isLoading,
  arrow = false,
  size = 'md'
}: SecondaryButtonProps) {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const isIconButton = Boolean(leftIcon || rightIcon);
  return (
    <Box
      zIndex={2}
      onClick={onClick}
      style={{ maskImage: '-webkit-radial-gradient(white, black)' }}
      position={'relative'}
      rounded={'lg'}
      // boxShadow={"lg"}
      overflow={'hidden'}
      p={`${borderWidth}px`}
      borderRadius={isDesktop ? '12px' : '6px'}
      background={disabled ? 'gray.600' : ''}
      _after={{
        background:
          'linear-gradient(105deg,transparent,hsla(0,0%,100%,.4) 70%,transparent)',
        transform: 'translate3d(-100%,0,0)',
        content: '""',
        position: 'absolute',
        zIndex: 3,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }}
      _hover={
        disabled
          ? {}
          : {
              backdropFilter: 'blur(0.1875rem)',
              _after: {
                transition: 'all 1s ease 0s',
                transform: 'translate3d(100%,0,0)'
              },
              _before: {
                width: `calc(120% + ${borderWidth * 2}px)`,
                paddingTop: '120%',
                position: 'absolute',
                top: 0,
                right: `calc(-10% - ${borderWidth * 2}px)`,
                display: 'block',
                zIndex: 1,
                background:
                  'linear-gradient(120deg,#32C5FF 0%, #B620E0 51%, #F7B500 100%)',
                content: '""',
                transform: `translate(0,calc(-50% +  ${borderWidth + 26}px))`,
                animation: `${ButtonBorderSwirl} .7s cubic-bezier(.57,.86,.72,.93) infinite`
              }
            }
      }
    >
      <Button
        size={size}
        disabled={disabled}
        justifyContent={'space-between'}
        zIndex={2}
        isLoading={isLoading}
        py={arrow ? '22px' : '10px'}
        px={'16px'}
        leftIcon={isIconButton ? <Box w={'32px'}>{leftIcon}</Box> : undefined}
        backgroundColor={'#164AFF'}
        backdropFilter={'blur(.1875rem)'}
        borderRadius={isDesktop ? '8px' : '6px'}
        boxSizing={'border-box'}
        rightIcon={
          isIconButton ? (
            <Box w={'32px'} display={'flex'} justifyContent={'flex-end'}>
              {rightIcon}
            </Box>
          ) : undefined
        }
        _hover={{
          color: 'black'
        }}
        color={'#ffffff'}
        w={'full'}
      >
        <Text
          textAlign="center"
          variant="title_md"
          color={'#DEDEE6'}
          fontSize={'14px'}
        >
          {title}
        </Text>
        {arrow ? <i className="fa fa-arrow-right"></i> : null}
      </Button>
    </Box>
  );
}
