import {
  Box,
  Center,
  Heading,
  keyframes,
  useBreakpointValue
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import landingPageBackground from '../../../assets/highswap-landing/landing_page_background_logos.svg';
import LandingNavBar from '../../elements/home-highswap/LandingNavBar';

export default function LandingPageIntro() {
  const isPhone = useBreakpointValue({ base: true, md: false });

  const animationKeyframes = keyframes`
  0% {top: 1.5px; left: 1.5px; }
  20% { top: -1.5px; left: -1.5px; }
  40% { top: 3px; left: 3px; }
  60% { top: -1.5px; left: 3px; }
  80% { top: 3px; left: -1.5px; }
  100% { top: 1.5px; left: 1.5px; }`;

  const animationRed = `${animationKeyframes} 0.5s infinite`;
  const animationBlue = `${animationKeyframes} 0.5s reverse infinite`;

  return (
    <>
      <Box
        background="#17171D"
        bgImage={{ md: landingPageBackground }}
        bgPosition={{ md: 'top right' }}
        bgSize={{ md: 'auto' }}
        bgRepeat={{ md: 'no-repeat' }}
        mt="5"
      >
        <LandingNavBar />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          pt={{ base: '4', md: '28' }}
          pb={{ base: '10', md: '28' }}
          flexDirection="column"
          pl={{ base: '5', md: '28' }}
          pr={{ base: '5', md: '28' }}
        >
          <Center
            color="#65E262"
            fontSize={'12px'}
            letterSpacing={'3px'}
            fontWeight={700}
          >
            NFT SELLING AGREGGATOR
          </Center>
          <Heading variant="heading">
            <Center
              mt="6"
              color="#F7FBFE"
              fontSize={{ base: '40px', md: '56px' }}
              textAlign="center"
              position="relative"
              fontFamily={'ChakraPetch-Bold'}
              padding="1.5px"
            >
              <Center zIndex={2}>Sell your NFTs instantly</Center>
              <Center
                as={motion.div}
                animation={animationRed}
                display="block"
                color="#6FFF6C"
                fontSize={{ base: '40px', md: '56px' }}
                position="absolute"
                fontFamily={'ChakraPetch-Bold'}
                zIndex="0"
              >
                Sell your NFTs instantly
              </Center>
              <Center
                as={motion.div}
                animation={animationBlue}
                display="block"
                color="#C12828"
                fontSize={{ base: '40px', md: '56px' }}
                position="absolute"
                fontFamily={'ChakraPetch-Bold'}
                zIndex="0"
              >
                Sell your NFTs instantly
              </Center>
            </Center>
          </Heading>
          <Center
            color="#F7FBFE"
            fontSize={{ base: '24px', md: '48px' }}
            pt={3}
            textAlign="center"
            pl={5}
            pr={5}
            pb={3}
          >
            Highest offers across all marketplaces
          </Center>
          <Center>
            {isPhone ? (
              <Box
                bgImage={landingPageBackground}
                w="375px"
                height="300px"
                bgSize={'150%'}
                bgPosition={'top right'}
              />
            ) : (
              ''
            )}
          </Center>
        </Box>
      </Box>
    </>
  );
}
