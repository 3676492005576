import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllActionsOf } from 'redux_store/utils/Actions';
import { AssetsInfo, Collection } from 'services/api/sell/model';

interface DetailState {
  detail: Record<string, { collection: Collection; assetsInfo: AssetsInfo }>;
}

const initialState: DetailState = {
  detail: {}
};

const slice = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    addDetail(state, action: PayloadAction<{ key: string; collectionObject: Collection; object: AssetsInfo }>) {
      const { key, collectionObject, object } = action.payload;
      state.detail[key] = { collection: collectionObject, assetsInfo: object };
    },
  }
});

const { actions, reducer } = slice;

export type DetailActionsType = AllActionsOf<typeof actions>;
export { actions as detailActions, reducer as detailReducer };
