import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { UserWallet } from 'components/elements';
import { Link } from 'react-router-dom';
export const MobileFooter = () => {
  return (
    <footer className="page-footer bg-white dark:bg-jacarta-900">
      <Box h={'1px'} background={`#404045`} />
      <Stack className="bg-[#212124] px-2 py-8 md:px-14">
        <Box w={'100%'} m={'auto'}>
          <Stack direction={'column'}>
            <Box alignSelf="center">
              <Link to="/" className="shrink-0">
                <Flex justifyContent="center">
                  <Image src={require('assets/headerLogo@2x.png')} w="47px" />
                  <Text
                    color="#ffffff"
                    alignSelf="center"
                    fontSize="24px"
                    paddingLeft="9px"
                  >
                    HIGHSWAP
                  </Text>
                </Flex>
              </Link>

              <Text
                marginTop="30px"
                textAlign="center"
                paddingX="40px"
                color="#777787"
                fontSize="12px"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Volutpat aliquet eleifend auctor laoreet aliquam dignissim morbi
                pellentesque
              </Text>

              <Flex marginY="36px" justifyContent="center" marginX="auto">
                <UserWallet />
              </Flex>

              <Text textAlign="center" color={'#515167'} variant="title_md">
                © 2022 HighSwap
              </Text>
            </Box>
            <Box flex={1} />

            <Flex>
              <Stack margin="auto" pt={4} direction={'row'} spacing={8}>
                <a
                  target={'_blank'}
                  rel="noreferrer"
                  href="https://twitter.com/highswap"
                >
                  <i
                    style={{ fontSize: 24, color: 'white' }}
                    className="fa-brands fa-twitter"
                  ></i>
                </a>
                <a
                  target={'_blank'}
                  rel="noreferrer"
                  href="https://discord.gg/cedar"
                >
                  <i
                    style={{ fontSize: 24, color: 'white' }}
                    className="fa-brands fa-discord"
                  ></i>
                </a>
                <a
                  target={'_blank'}
                  rel="noreferrer"
                  href="https://medium.com/@CedarBNPL"
                >
                  <i
                    style={{ fontSize: 24, color: 'white' }}
                    className="fa-brands fa-medium"
                  ></i>
                </a>
                <a>
                  <i
                    style={{ fontSize: 24, color: 'white' }}
                    className="fa-brands fa-telegram"
                  ></i>
                </a>
              </Stack>
            </Flex>
          </Stack>
        </Box>
      </Stack>
    </footer>
  );
};
