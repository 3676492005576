import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaginationState {
  currentPage: number;
  pageSize: number;
  totalItems: number;
}

const initialState: PaginationState = {
  currentPage: 0,
  pageSize:8,
  totalItems: 0, // Initial value of totalItems is 0
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    nextPage(state) {
      if (state.currentPage < Math.ceil(state.totalItems / state.pageSize) - 1) {
        state.currentPage += 1;
      }
    },
    prevPage(state) {
      if (state.currentPage > 0) {
        state.currentPage -= 1;
      }
    },
    setPage(state, action: PayloadAction<number>) {
      const targetPage = action.payload;
      if (targetPage >= 0 && targetPage < Math.ceil(state.totalItems / state.pageSize)) {
        state.currentPage = targetPage;
      }
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    reset(state) {
      state.currentPage = 0;
      state.pageSize = 8;
      state.totalItems = 0;
    }
  }
});

export const { actions: paginationActions, reducer: paginationReducer } = paginationSlice;
