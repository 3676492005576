import {
  ExternalProvider,
  Web3Provider as Provider
} from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';

interface Web3ProviderProps {
  children: JSX.Element;
}

function getLibrary(provider: ExternalProvider) {
  const library = new Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

export const Web3Provider = (props: Web3ProviderProps) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      {props.children}
    </Web3ReactProvider>
  );
};
