import {
  Box,
  Flex,
  Image,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { MobileFooter } from './MobileFooter';
const Footer = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <>
      {isDesktop && (
        <footer className="page-footer bg-white dark:bg-jacarta-900">
          <Box
            h={'1px'}
            // mt={12}
            background={`#404045`}
          />
          <Stack className="bg-[#212124] px-2 py-8 md:px-6">
            <Box w={'100%'} m={'auto'}>
              <Stack direction={'row'}>
                <Box>
                  <Link to="/" className="shrink-0">
                    <Flex>
                      <Image
                        src={require('assets/headerLogo@2x.png')}
                        w="47px"
                      />
                      <Text
                        color="#ffffff"
                        alignSelf="center"
                        fontSize="24px"
                        paddingLeft="9px"
                      >
                        HIGHSWAP
                      </Text>
                    </Flex>
                  </Link>
                  <Text pt={4} color={'#ADADB1'} variant="title_md">
                    © 2022 HighSwap
                  </Text>
                </Box>
                <Box flex={1} />
                <Stack minW={'150px'}>
                  <Text
                    textAlign={'right'}
                    color={'whiteAlpha.600'}
                    variant="title_md"
                  >
                    Join the community
                  </Text>
                  <Stack pt={4} direction={'row'} spacing={4}>
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://twitter.com/highswap"
                    >
                      <i
                        style={{ fontSize: 24, color: 'white' }}
                        className="fa-brands fa-twitter"
                      ></i>
                    </a>
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://discord.gg/cedar"
                    >
                      <i
                        style={{ fontSize: 24, color: 'white' }}
                        className="fa-brands fa-discord"
                      ></i>
                    </a>
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href="https://medium.com/@CedarBNPL"
                    >
                      <i
                        style={{ fontSize: 24, color: 'white' }}
                        className="fa-brands fa-medium"
                      ></i>
                    </a>
                    <a>
                      <i
                        style={{ fontSize: 24, color: 'white' }}
                        className="fa-brands fa-telegram"
                      ></i>
                    </a>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </footer>
      )}

      {!isDesktop && <MobileFooter />}
    </>
  );
};

export default Footer;
