import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { formatEther } from '@ethersproject/units';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { NoEthereumProviderError } from '@web3-react/injected-connector';
import {
  UserRejectedRequestError,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from '@web3-react/walletconnect-connector';
import SecondaryButton from 'components/elements/buttons/SecondaryButton';
import useScreen from 'hooks/useScreen';
import { injected, walletConnect } from 'hooks/wallet/connectors';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ellipsisAddress } from 'utils';

import PrimaryButton from '../buttons/PrimaryButton';
import WalletIcon from '../icon/WalletIcon';

export enum WalletConnector {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect'
}

export const getChainName = (chainId: number | undefined) => {
  switch (chainId) {
    case 1:
      return 'Etherum';
    case 3:
      return 'Ropsten';
    case 4:
      return 'Rinkeby';
    case 5:
      return 'Gorli';
    case 42:
      return 'Kovan';
  }
};

export default function UserWallet() {
  const context = useWeb3React();
  const screen = useScreen();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const walletDetailsModal = useDisclosure();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const [selectedConnector, setSelectedConnector] =
    useState<WalletConnector | null>(
      window.localStorage.getItem('wallectConnector') as WalletConnector
    );
  const [ethBalance, setEthBalance] = React.useState<any>();
  const { library, account, active, activate, chainId, error, deactivate } =
    context;
  const toast = useToast();

  function getErrorMessage(error: Error) {
    if (error instanceof NoEthereumProviderError) {
      return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network.";
    } else if (
      error instanceof UserRejectedRequestError ||
      error instanceof UserRejectedRequestErrorWalletConnect
    ) {
      return 'Please authorize this website to access your Ethereum account.';
    } else {
      console.error(error);
      return error.message || 'An unknown error occurred. Please try again.';
    }
  }

  useEffect(() => {
    if (error) {
      toast({
        title: 'Connection Error',
        description: getErrorMessage(error),
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }, [error, toast]);

  const handleActivate = (walletConnector: WalletConnector | null) => {
    window.localStorage.setItem(
      'wallectConnector',
      walletConnector || WalletConnector.MetaMask
    );
    setSelectedConnector(walletConnector);
    activate(
      walletConnector === WalletConnector.WalletConnect
        ? walletConnect
        : injected
    );

    if (isOpen) navigate('/inventory');
    setIsOpen(false);
  };

  useEffect(() => {
    if (!selectedConnector) return;

    if (selectedConnector === WalletConnector.MetaMask) {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true);
        }
      });
    }
  }, [activate]);

  useEffect(() => {
    if (library && account) {
      handleActivate(selectedConnector);
      library
        .getBalance(account)
        .then((balance: any) => {
          setEthBalance(balance);
        })
        .catch(() => {
          setEthBalance(undefined);
        });
    }
  }, [library, account, chainId, active]);

  const handleChangeAccount = () => {
    deactivate();
    setEthBalance(undefined);
    window.localStorage.removeItem('wallectConnector');
    setIsOpen(true);
  };

  const handleDisconnect = () => {
    deactivate();
    window.localStorage.removeItem('wallectConnector');
    navigate('/');
  };

  return (
    <Box>
      {!(active && account) ? (
        <SecondaryButton
          onClick={() => setIsOpen(true)}
          title="Connect Wallet"
        />
      ) : isDesktop ? (
        <Menu>
          <MenuButton>
            <Stack
              cursor={'pointer'}
              borderRadius={'12px'}
              bg={'#212124'}
              border={'1px'}
              borderColor={'#49494B'}
              alignItems={'center'}
              maxW={'220px'}
              py={'10px'}
              px={'20px'}
              direction={'row'}
            >
              <Text
                color={'rgb(253, 252, 255,0.6)'}
                noOfLines={1}
                variant="title_sm"
                fontSize={'16px'}
              >
                {ellipsisAddress(account)}
              </Text>
              <ChevronDownIcon color="#ADADB1" fontSize="25px" />
            </Stack>
          </MenuButton>
          <MenuList
            color={'rgb(253, 252, 255,0.6)'}
            borderColor="#404045"
            backgroundColor="#212124"
          >
            <MenuItem
              onClick={() => {
                navigate('/inventory');
              }}
              _focus={{
                backgroundColor: '#1A202C',
                coolor:'#ERE8F0'
              }}
              _active={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                coolor:'#ERE8F0'
              }}
              backgroundColor={'rgba(242, 242, 242, 0.05)'}
            >
              <svg
                width="1024px"
                height="20px"
                style={{ width: 20, marginRight: 9 }}
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <path
                  d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656zM492 400h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 144h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 144h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H492c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zM340 368a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm0 144a40 40 0 1 0 80 0 40 40 0 1 0-80 0zm0 144a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"
                  fill="#ffffff"
                />
              </svg>

              <span>Inventory</span>
            </MenuItem>
            <MenuItem
              _focus={{
                backgroundColor: '#1A202C',
                coolor:'#ERE8F0'
              }}
              _active={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                coolor:'#ERE8F0'
              }}
              backgroundColor={'rgba(242, 242, 242, 0.05)'}
              onClick={handleDisconnect}
            >
              <svg
                version="1.1"
                id="Capa_1"
                style={{ height: 16, marginRight: 10, marginLeft: 2 }}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 384.971 384.971"
              >
                <g>
                  <g id="Sign_Out">
                    <path
                      d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03
                          C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03
                          C192.485,366.299,187.095,360.91,180.455,360.91z"
                      fill="#ffffff"
                    />
                    <path
                      d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279
                          c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179
                          c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"
                      fill="#ffffff"
                    />
                  </g>
                </g>
              </svg>
              <span>Disconnect</span>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <PrimaryButton
          leftIcon={
            <Box marginRight="5px">
              <WalletIcon fontSize={17} />
            </Box>
          }
          className="border-2 border-[#164AFF] hover:border-gray-500 focus:outline-none active:outline-none"
          bgColor="#164aff33"
          paddingY={5}
          onClick={() => walletDetailsModal.onOpen()}
          title={ellipsisAddress(account)}
        />
      )}

      <Modal
        size={screen.isMobile ? 'full' : 'lg'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          color="white"
          background={'#17171D'}
          alignSelf={'center'}
          boxShadow={'2xl'}
          borderRadius={'20px'}
          px={10}
          pt={8}
          pb={10}
          w={'full'}
        >
          <ModalCloseButton color="#49494B" m={2} size={'lg'} />
          <Text textAlign={'center'} variant="title_3xl" color={'white'}>
            Connect a Wallet
          </Text>
          <Box mt={6}>
            <Text textAlign={'left'} variant="title_md" color={'white'}>
              Connect with one of our available wallet providers or create a new
              one
            </Text>
          </Box>
          <Stack mt={4} spacing={4}>
            <SecondaryButton
              onClick={() => {
                handleActivate(WalletConnector.MetaMask);
              }}
              leftIcon={<Image src={require('assets/icons/metamask.png')} />}
              title="Metamask"
            />
            <SecondaryButton
              onClick={() => {
                handleActivate(WalletConnector.WalletConnect);
              }}
              leftIcon={
                <Image src={require('assets/icons/WalletConnect.png')} />
              }
              title="WalletConnect"
            />
          </Stack>
        </ModalContent>
      </Modal>

      <Modal
        size={'full'}
        isOpen={walletDetailsModal.isOpen}
        onClose={walletDetailsModal.onClose}
      >
        <ModalOverlay />
        <ModalContent color="white" background={'#17171D'} py={8} w={'full'}>
          <ModalCloseButton m={2} size={'lg'} />
          <Box px={8} onClick={walletDetailsModal.onClose}>
            <Text variant="title_2xl">HIGHSWAP</Text>
          </Box>
          <Box
            padding="20px"
            borderTop={'1px solid'}
            borderColor={'#404045'}
            mt={8}
            pt={8}
          >
            <Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <Text variant="title_md" color={'white'}>
                  Connected Wallet
                </Text>
                <Box flex={1} />

                <Text
                  cursor={'pointer'}
                  onClick={handleChangeAccount}
                  textDecoration={'underline'}
                  variant="title_md"
                  color={'white'}
                >
                  Change
                </Text>
              </Stack>

              <Text color={'#ADADB1'} fontWeight={'bold'}>
                {account}
              </Text>
              <Stack
                borderRadius={4}
                maxW={'250px'}
                direction={'row'}
                alignItems={'center'}
                py={1}
                px={4}
                background={'#1C1C23'}
              >
                <Text variant="caption">Balance</Text>
                <Box flex={1} />
                <Text
                  variant="title_md"
                  color={'white'}
                  fontWeight={'bold'}
                  noOfLines={1}
                >
                  {ethBalance === undefined
                    ? '...'
                    : ethBalance === null
                    ? 'Error'
                    : `${parseFloat(formatEther(ethBalance)).toFixed(4)}`}{' '}
                  ETH
                </Text>
                <Box flex={1} />
                <Text>{getChainName(chainId)}</Text>
              </Stack>
            </Stack>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}
