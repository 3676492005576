import { Icon } from '@chakra-ui/react';

interface CartIconProps {
  color?: string;
  fontSize?: string;
}

export const CartIcon = (props: CartIconProps) => {
  return (
    <Icon fontSize={props.fontSize || '12px'} viewBox="0 0 48 53">
      <svg
        width="48"
        height="53"
        viewBox="0 0 48 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.5 37.6365H13.0875L7.85625 6.25918C7.79504 5.88342 7.61536 5.54281 7.3483 5.29629C7.08123 5.04977 6.74358 4.91283 6.39375 4.90918H3"
          stroke={props.color || '#49494B'}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 45.8185C17.0711 45.8185 18.75 43.987 18.75 41.7276C18.75 39.4683 17.0711 37.6367 15 37.6367C12.9289 37.6367 11.25 39.4683 11.25 41.7276C11.25 43.987 12.9289 45.8185 15 45.8185Z"
          stroke={props.color || '#49494B'}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5 45.8185C36.5711 45.8185 38.25 43.987 38.25 41.7276C38.25 39.4683 36.5711 37.6367 34.5 37.6367C32.4289 37.6367 30.75 39.4683 30.75 41.7276C30.75 43.987 32.4289 45.8185 34.5 45.8185Z"
          stroke={props.color || '#49494B'}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7188 29.4545H35.2688C35.9699 29.4568 36.6494 29.1894 37.1878 28.6994C37.7262 28.2093 38.089 27.5279 38.2125 26.7749L40.5 13.0908H9"
          stroke={props.color || '#49494B'}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
