import { httpClient } from 'services/api/httpClient';

import { Contract, ContractDetail } from './model';

export async function getCollection(slug: string): Promise<ContractDetail> {
  const response = await httpClient.sendRequest({
    url: `contract/${slug}`,
    method: 'GET'
  });
  return response.data;
}

export async function getCollections(): Promise<Contract[]> {
  const response = await httpClient.sendRequest({
    url: `contract`,
    method: 'GET'
  });
  return response.data;
}
