import {
  Box,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react';
import logo from 'assets/images/logo.gif';
import {
  CustomInput,
  PrimaryButton,
  SecondaryButton
} from 'components/elements';
import React, { useContext, useState } from 'react';

export function useComingSoonModalOpen() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [code, setCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [submitCallback, setSubmitCallback] = useState<() => void>();
  function handleSetIsOpen(open: boolean) {
    setIsOpen(open);
  }
  function handleModalOpen(callback: () => void) {
    setIsOpen(true);
    setSubmitCallback(() => callback);
  }
  function onSubmit() {
    if (code === 'testmvp2022') {
      submitCallback && submitCallback();
      setIsOpen(false);
    } else {
      setIsInvalid(true);
    }
  }
  return {
    isOpen,
    handleSetIsOpen,
    code,
    setCode,
    isInvalid,
    setIsInvalid,
    setSubmitCallback,
    onSubmit,
    handleModalOpen
  };
}

export const ComingSoonModalContext = React.createContext<any>(null);

export default function ComingSoonModal() {
  const {
    isOpen,
    handleSetIsOpen,
    code,
    setCode,
    isInvalid,
    setIsInvalid,
    onSubmit
  } = useContext(ComingSoonModalContext);

  return (
    <Modal
      isCentered={true}
      onClose={() => handleSetIsOpen(false)}
      isOpen={isOpen}
    >
      <ModalOverlay
        background={'rgba(65, 65, 65, 0.6)'}
        backdropFilter={'blur(36px)'}
      />
      <ModalContent p={8} background={'rgba(255, 255, 255, 0.8)'}>
        <Stack align={'center'}>
          <Image w={'160px'} src={logo} />
          <Text py={4} color={'black'} variant={'title_2xl'}>
            Enter access code
          </Text>
          <Box w={'full'} py={6}>
            <CustomInput
              borderColor={'gray.500'}
              errorMessage="Sry this is not a valid code"
              isInvalid={isInvalid}
              placeholder="Access code"
              color="black"
              value={code}
              onChange={(e) => {
                setIsInvalid(false);
                setCode(e.target.value);
              }}
            />
          </Box>
          <Stack spacing={8} w={'full'} direction={'row'} align={'center'}>
            <Box flex={1}>
              <PrimaryButton
                onClick={() => onSubmit()}
                title="Try Access"
                size="sm"
              ></PrimaryButton>
            </Box>
            <Box flex={1}>
              <SecondaryButton
                onClick={() => handleSetIsOpen(false)}
                title="Back"
              ></SecondaryButton>
            </Box>
          </Stack>
        </Stack>
      </ModalContent>
    </Modal>
  );
}
