import axios from 'axios';
import { httpClient } from 'services/api/httpClient';
import { Nft, OrderResponse } from 'services/api/nft/model';
import {
  AuthProps,
  AuthResponse,
  ResetPasswordProps,
  User,
  WalletAddress
} from 'services/api/user/model';

export async function emailSubscribe(email: string): Promise<string> {
  const response = await axios({
    url: '',
    method: 'put',
    data: {
      email: email
    },
    timeout: 3000
  });
  return response.data;
}

export async function login(data: AuthProps): Promise<AuthResponse> {
  const response = await httpClient.sendRequest({
    url: 'auth/login',
    method: 'POST',
    data: data
  });
  return response.data;
}

export async function sendResetLink(data: {
  email: string;
}): Promise<AuthResponse> {
  const response = await httpClient.sendRequest({
    url: 'auth/password/reset/forgot',
    method: 'POST',
    data: data
  });
  return response.data;
}

export async function sendVerifyLink(data?: {
  email: string;
}): Promise<AuthResponse> {
  const response = await httpClient.sendRequest({
    url: 'auth/resend-verification',
    method: 'POST',
    data: data
  });
  return response.data;
}

export async function resetPassword(
  data: ResetPasswordProps
): Promise<boolean> {
  const response = await httpClient.sendRequest({
    url: 'auth/password/reset',
    method: 'POST',
    data: data
  });
  return response.data;
}

export async function register(data: AuthProps): Promise<AuthResponse> {
  const response = await httpClient.sendRequest({
    url: 'auth/register',
    method: 'POST',
    data: data
  });
  return response.data;
}

export async function getUserProfile(): Promise<User> {
  const response = await httpClient.sendRequest({
    url: 'auth/me',
    method: 'GET'
  });
  return response.data;
}

export async function getUserOrders(): Promise<OrderResponse[]> {
  const response = await httpClient.sendRequest({
    url: 'user/order',
    method: 'GET'
  });
  return response.data;
}

export async function getUserFavorite(): Promise<Nft[]> {
  const response = await httpClient.sendRequest({
    url: 'user/favorite',
    method: 'GET'
  });
  return response.data;
}

export async function getOwnedItems(): Promise<OrderResponse[]> {
  const response = await httpClient.sendRequest({
    url: 'user/complete',
    method: 'GET'
  });
  return response.data?.orders ?? [];
}

export async function getWithdrawnOrder(): Promise<OrderResponse[]> {
  const response = await httpClient.sendRequest({
    url: 'user/withdrawn',
    method: 'GET'
  });
  return response.data?.orders ?? [];
}
export async function getWithdrawingOrder(): Promise<OrderResponse[]> {
  const response = await httpClient.sendRequest({
    url: 'user/withdrawing',
    method: 'GET'
  });
  return response.data?.orders ?? [];
}

export async function getInProgressItems(): Promise<OrderResponse[]> {
  const response = await httpClient.sendRequest({
    url: 'user/lent',
    method: 'GET'
  });
  return response.data?.orders ?? [];
}

export async function sendCode(): Promise<any> {
  const response = await httpClient.sendRequest({
    url: 'user/send-code',
    method: 'POST'
  });
  return response.data;
}

export async function addWalletAddress(
  address: string,
  chain_type: number,
  code: string,
  name?: string
): Promise<any> {
  const response = await httpClient.sendRequest({
    url: 'user/wallet/add ',
    method: 'POST',
    data: {
      address,
      name,
      chain_type,
      code
    }
  });
  return response.data;
}

export async function deleteWalletAddress(
  id: number,
  chain_type: number,
  code: string,
  name?: string
): Promise<any> {
  const response = await httpClient.sendRequest({
    url: `user/wallet/delete/${id}`,
    method: 'POST',
    data: {
      id,
      name,
      chain_type,
      code
    }
  });
  return response.data;
}

export async function getWalletAddress(): Promise<WalletAddress[]> {
  const response = await httpClient.sendRequest({
    url: 'user/wallet ',
    method: 'GET'
  });
  return response.data;
}
