import { Error, Home, Detail, Inventory, Term } from 'components/views';

export interface RouteItem {
  path: string;
  element: JSX.Element;
  title: string;
}

export const routes: RouteItem[] = [
  { path: '/', element: <Home />, title: 'Home' },
  { path: '/term', element: <Term />, title: 'Term' },
  {
    path: '/inventory',
    element: <Inventory />,
    title: 'User Inventory'
  },
  {
    path: '/inventory/:address',
    element: <Inventory />,
    title: 'User Inventory'
  },
  {
    path: '/detail/:collection/:itemid',
    element: <Detail />,
    title: 'User Inventory'
  },
  { path: '/*', element: <Error />, title: 'Error' }
];
