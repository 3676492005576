import collectionSaga from 'redux_store/collection/saga';
import overlaySaga from 'redux_store/overlay/saga';
import userSaga from 'redux_store/user/saga';
import { all, AllEffect, ForkEffect } from 'redux-saga/effects';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  try {
    yield all([...collectionSaga, ...userSaga, ...overlaySaga]);
  } catch (err) {
    console.log('error caught in rootsaga', err);
  }
}
