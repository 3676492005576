export interface Filter {
  filterOffer: boolean;
  collectionFilter: string[];
  platformFilter: string[];
  minprice: number;
  maxprice: number;
}

export enum SortingOptions {
  PriceHighLow = "Price high to low",
  PriceLowHigh = "Price low to high",
  AcquiredEarly = "Earliest acquired",
  AcquiredLate = "Latest acquired"
}
