import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CollectionState } from 'redux_store/collection/model';
import { AllActionsOf } from 'redux_store/utils/Actions';
import { Contract } from 'services/api/collection/model';

export const initialState: CollectionState = {
  collections: []
};

const slice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    getUserCollections() {},
    getUserCollectionsSuccess(state, action: PayloadAction<Contract[]>) {
      state.collections = action.payload;
    },
    getUserCollectionsFailed(state, action: PayloadAction<unknown>) {}
  }
});

const { actions, reducer } = slice;
export type CollectionActionsType = AllActionsOf<typeof actions>;
export { actions as collectionActions, reducer as collectionReducer };
