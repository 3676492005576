import { useToast } from '@chakra-ui/react';

export const useCustomToast = () => {
  const toast = useToast({
    position: 'bottom',
    containerStyle: {
      background: 'none'
    }
  });

  return toast;
};
