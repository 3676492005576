import { Button, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

export interface PrimaryButtonProps {
  onClick?: () => void;
  title?: string;
  leftIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  rightIcon?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  disabled?: boolean;
  isLoading?: boolean;
  bgColor?: string;
  className?: string;
  paddingY?: number;
  size?: string;
}

export default function PrimaryButton({
  onClick,
  title,
  leftIcon,
  rightIcon,
  disabled,
  isLoading,
  bgColor = '',
  className = '',
  paddingY = 7,
  size = 'md'
}: PrimaryButtonProps) {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Button
      size={size}
      className={className}
      isLoading={isLoading}
      disabled={disabled}
      py={paddingY}
      borderRadius={isDesktop ? '12px' : '6px'}
      boxShadow={'lg'}
      leftIcon={leftIcon}
      bgColor={bgColor || '#164AFF'}
      bgSize={'cover'}
      bgPos={'center'}
      rightIcon={rightIcon}
      // rounded={"lg"}
      _hover={{
        bgColor: bgColor ? '' : 'rgba(20, 71, 255, 0.7)'
      }}
      w={'full'}
      onClick={onClick}
    >
      <Text variant="title_md">{title}</Text>
    </Button>
  );
}
