import { Icon } from '@chakra-ui/react';

export interface IconProps {
  fontSize: number[] | number;
}

export default function WalletIcon(props: IconProps) {
  return (
    <Icon fontSize={props.fontSize} viewBox="0 0 17 16">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 9.5C12.4333 9.5 12.7917 9.35833 13.075 9.075C13.3583 8.79167 13.5 8.43333 13.5 8C13.5 7.56667 13.3583 7.20833 13.075 6.925C12.7917 6.64167 12.4333 6.5 12 6.5C11.5667 6.5 11.2083 6.64167 10.925 6.925C10.6417 7.20833 10.5 7.56667 10.5 8C10.5 8.43333 10.6417 8.79167 10.925 9.075C11.2083 9.35833 11.5667 9.5 12 9.5ZM9.625 12C9.15833 12 8.771 11.8457 8.463 11.537C8.15433 11.229 8 10.8417 8 10.375V5.625C8 5.15833 8.15433 4.77067 8.463 4.462C8.771 4.154 9.15833 4 9.625 4H15.375C15.8417 4 16.2293 4.154 16.538 4.462C16.846 4.77067 17 5.15833 17 5.625V10.375C17 10.8417 16.846 11.229 16.538 11.537C16.2293 11.8457 15.8417 12 15.375 12H9.625ZM1.625 16C1.15833 16 0.771 15.846 0.463 15.538C0.154333 15.2293 0 14.8417 0 14.375V1.625C0 1.15833 0.154333 0.771 0.463 0.463C0.771 0.154333 1.15833 0 1.625 0H14.375C14.8417 0 15.2293 0.154333 15.538 0.463C15.846 0.771 16 1.15833 16 1.625V3H9.625C8.825 3 8.18767 3.23733 7.713 3.712C7.23767 4.18733 7 4.825 7 5.625V10.375C7 11.175 7.23767 11.8127 7.713 12.288C8.18767 12.7627 8.825 13 9.625 13H16V14.375C16 14.8417 15.846 15.2293 15.538 15.538C15.2293 15.846 14.8417 16 14.375 16H1.625Z"
          fill="#F8FBFF"
        />
      </svg>
    </Icon>
  );
}
