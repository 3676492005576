import { Platform } from 'services/api/offer/model';

import LooksrareIcon from './icon/LooksrareIcon';
import OpenseaIcon from './icon/OpenseaIcon';
import SudoswapIcon from './icon/SudoswapIcon';
import X2Y2Icon from './icon/X2Y2Icon';

interface MarketLogoProps {
  market?: Platform;
  size?: number;
}

export const MarketLogo = (props: MarketLogoProps) => {
  const { market, size = 24 } = props;

  if (market === Platform.OPENSEA) {
    return <OpenseaIcon fontSize={size}></OpenseaIcon>;
  }

  if (market === Platform.LOOKSRARE) {
    return <LooksrareIcon fontSize={size}></LooksrareIcon>;
  }

  if (market === Platform.X2Y2) {
    return <X2Y2Icon fontSize={size}></X2Y2Icon>;
  }

  if (market === Platform.SUDOSWAP) {
    return <SudoswapIcon fontSize={size}></SudoswapIcon>;
  }

  return null;
};
