import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { CustomIconButton, SecondaryButton } from 'components/elements';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';

interface CaroselParams {
  children: any;
  max_width?: string | number;
  hideBtn?: boolean;
  step?: number;
  autoSlide?: boolean;
  moveBtn?: boolean;
  seeAll?: boolean;
  seeAllLink?: string;
}

const Container = styled.div`
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  scroll-behavior: smooth;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
  .buttons {
    position: absolute;
    top: -50px;
    right: 0px;
    transform: translateY(-50%);
    display: flex;
    gap: 20px;
    width: fit-content;
    button {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px 12px;
      cursor: pointer;
    }
    .prev {
      /* transform: translateX(10px); */
    }
    .next {
      /* transform: translateX(-10px); */
    }
  }
`;

interface StateCaroselParams {
  width_carosel?: number;
  width_childrens?: number;
  qnt_childrens?: number;
  max_width_carousel?: number | string;
}

/**
 * Componente de Carosel simples. Para utilizar  certifique-se de que os childrens tem a mesma largura(width), para ter uma maior experiencia.
 * @param param0 {CaroselParams}
 * @returns {JSX.Element}
 */
const Carosel: React.FC<CaroselParams> = ({
  children,
  max_width,
  hideBtn,
  step,
  autoSlide,
  moveBtn = false,
  seeAllLink,
  seeAll
}: CaroselParams): JSX.Element => {
  const Carousel: any = useRef<HTMLDivElement>(null);

  const [stateCarosel, setStateCarousel] = useState<StateCaroselParams>();
  const intervalRef = useRef<any>(null);
  const autoIntervalRef = useRef<any>(null);

  const props: any = { children, max_width, hideBtn, step, autoSlide };
  const { inViewport } = useInViewport(
    Carousel,
    // options,
    // (config = { disconnectOnLeave: false }),
    props
  );

  const handleCarousel = useCallback(() => {
    if (Carousel.current) {
      const carousel = Carousel.current;
      setStateCarousel({
        ...stateCarosel,
        width_carosel: carousel.clientWidth,
        qnt_childrens: carousel.children.length,
        width_childrens: step ?? carousel.children.item(0)?.clientWidth,
        max_width_carousel:
          carousel.children && carousel.children.item(0)
            ? (carousel.children.length - 1) *
              carousel.children.item(0)!.clientWidth
            : '100px'
      });
    }
  }, [setStateCarousel]);

  const setAutoInt = () => {
    if (Carousel.current!.scrollLeft || Carousel.current!.scrollLeft === 0) {
      if (Carousel.current!.scrollLeft > 0) {
        Carousel.current!.scrollLeft = 0;
      }
      Carousel.current!.scrollLeft += step ?? 200;
    }
  };

  const handleAutoPlay = () => {
    if (autoSlide && !autoIntervalRef.current) {
      setTimeout(() => {
        autoIntervalRef.current = setInterval(setAutoInt, 2000);
      }, 2000);
    }
  };

  const handleCarouselActionRight = () => {
    stopAutoplay();
    Carousel.current!.scrollLeft += stateCarosel!.width_childrens!;
  };
  const handleCarouselActionLeft = () => {
    stopAutoplay();
    Carousel.current!.scrollLeft -= stateCarosel!.width_childrens!;
  };

  const rightPressed = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      Carousel.current!.scrollLeft += stateCarosel!.width_childrens!;
    }, 300);
  };

  const leftPressed = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      Carousel.current!.scrollLeft -= stateCarosel!.width_childrens!;
    }, 300);
  };

  const buttonReleased = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const stopAutoplay = () => {
    if (autoIntervalRef.current) {
      clearInterval(autoIntervalRef.current);
      autoIntervalRef.current = null;
    }
  };

  const handleTouch = (e: any) => {
    stopAutoplay();
  };

  useEffect(() => handleCarousel(), [handleCarousel]);

  useEffect(handleAutoPlay, []);

  return (
    <div
      className={`relative w-full ${
        'max-w-' + (max_width || '[' + stateCarosel?.max_width_carousel + 'px]')
      }`}
    >
      <Container
        onMouseOver={() => {
          stopAutoplay();
        }}
        onMouseLeave={() => {
          handleAutoPlay();
        }}
        ref={Carousel}
        onTouchMove={handleTouch}
      >
        <Box
          display={'flex'}
          gap="20px"
          animation={inViewport ? 'fadeInRightCarousel 1.5s ease' : ''}
        >
          {children}
        </Box>
        {!hideBtn && (
          <Stack
            position={'absolute'}
            direction={['column', 'row']}
            top={['-100px', '-60px']}
            right={'0px'}
          >
            <Stack direction={'row'} spacing={'20px'}>
              <CustomIconButton
                onClick={handleCarouselActionLeft}
                onMouseDown={leftPressed}
                onMouseUp={buttonReleased}
                icon={<ChevronLeftIcon />}
              />
              <CustomIconButton
                onClick={handleCarouselActionRight}
                onMouseDown={rightPressed}
                onMouseUp={buttonReleased}
                icon={<ChevronRightIcon />}
              />
            </Stack>
            {seeAll && (
              <Box w={'32'} pl={'25px'}>
                <SecondaryButton
                  arrow={true}
                  onClick={() => {
                    if (seeAllLink) {
                      window.location.pathname = seeAllLink;
                    }
                  }}
                  title="See All"
                ></SecondaryButton>
              </Box>
            )}
          </Stack>
        )}
        {/* <Box
          blur={"19px"}
          w={["0", "0", "0", "150px"]}
          h={"513px"}
          pos={"absolute"}
          zIndex='99'
          left={"0"}
          top={"0"}
          backdropFilter={"blur(10px)"}></Box>
        <Box
          blur={"19px"}
          w={["0", "0", "0", "150px"]}
          h={"513px"}
          pos={"absolute"}
          zIndex='99'
          right={"0"}
          top={"0"}
          backdropFilter={"blur(10px)"}></Box> */}
      </Container>
    </div>
  );
};

export default Carosel;
