import { ActionFromReducer, combineReducers, Dispatch } from 'redux';
import { collectionReducer } from 'redux_store/collection/slice';
import { nftListReducer } from 'redux_store/nftList/slice';
import { overlayReducer } from 'redux_store/overlay/overlay.slice';
import { sellingCartReducer } from 'redux_store/sellingCart/slice';
import { userReducer } from 'redux_store/user/slice';
import { paginationReducer } from 'redux_store/pagination/slice';
import { detailReducer } from 'redux_store/detail/slice';

import { searchReducer } from './search/slice';
import { walletReducer } from './wallet/slice';
import { filterReducers } from './filter/slice';

export type RootState = ReturnType<typeof rootReducer>;
export type ReduxAction = ActionFromReducer<typeof rootReducer>;
export type RootDispatch = Dispatch<ReduxAction>;

const rootReducer = combineReducers({
  collection: collectionReducer,
  user: userReducer,
  overlay: overlayReducer,
  sellingCart: sellingCartReducer,
  detail: detailReducer,
  nftList: nftListReducer,
  wallet: walletReducer,
  search: searchReducer,
  filter: filterReducers,
  pagination: paginationReducer,
});

export default rootReducer;
