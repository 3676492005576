import {
  Box,
  FormControl,
  FormLabel,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import PrimaryButton from 'components/elements/buttons/PrimaryButton';
import CustomInput from 'components/elements/input/CustomInput';
import useScreen from 'hooks/useScreen';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { overlayActions } from 'redux_store/overlay/overlay.slice';
import { RootState } from 'redux_store/rootReducer';
import { userActions } from 'redux_store/user/slice';
import { authApiErrorHandler, HttpStatus } from 'services/api/httpClient';
import { login } from 'services/api/user';
import { saveToken } from 'services/api/util';

export default function LoadingModal() {
  const { isLoginOverlayOpen } = useSelector((s: RootState) => s.overlay);
  const screen = useScreen();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      setError('');
      setIsLoading(true);
      const response = await login({ email, password });
      saveToken(response.access_token);
      dispatch(userActions.authSuccess());

      toast({
        title: 'Login Success',
        description: 'Welcome',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } catch (e) {
      const error = authApiErrorHandler(e, navigate);
      if (error.status === HttpStatus.Unprocessable) {
        if (error.data?.errors?.email) {
          setEmailError(error.data?.errors?.email[0]);
        }
        if (error.data?.errors?.password) {
          setPasswordError(error.data?.errors?.password[0]);
        }
      } else {
        setEmailError(' ');
        setPasswordError(' ');
        setError(error?.data?.error?.data ?? 'Unknown Error');
      }
    } finally {
      setIsLoading(false);
      dispatch(overlayActions.toggleLoginOverlay({ isOpen: false }));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };
  return (
    <Modal
      size={screen.isMobile ? 'full' : 'xl'}
      isCentered={true}
      onClose={() =>
        dispatch(overlayActions.toggleLoginOverlay({ isOpen: false }))
      }
      isOpen={isLoginOverlayOpen}
    >
      <ModalOverlay background={'transparent'} />

      <ModalContent alignItems={'center'} py={8} background={'white'}>
        <ModalCloseButton m={2} size={'lg'} />
        <Stack px={8} w={'full'} flex={1} align={'center'}>
          <Link to={'/'}>
            <video
              autoPlay={true}
              controls={false}
              muted={true}
              loop={true}
              width={120}
              playsInline={true}
              src={require('assets/images/logo.mp4')}
            >
              Your browser does not support the HTML5 Video element.
            </video>
          </Link>
          <Text pt={4} fontWeight={'bold'} color={'black'} variant="title_3xl">
            Log in
          </Text>

          <FormControl
            onKeyDown={handleKeyPress}
            color={'gray.400'}
            isRequired={true}
          >
            <Box w={'full'} pt={4}>
              <FormLabel color={'black'} htmlFor="email">
                Email
              </FormLabel>
              <CustomInput
                errorMessage={emailError}
                value={email}
                isInvalid={Boolean(emailError)}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError('');
                }}
                type={'email'}
              />
            </Box>
            <Box w={'full'} pt={4}>
              <FormLabel color={'black'} htmlFor="password">
                Password
              </FormLabel>
              <CustomInput
                value={password}
                errorMessage={passwordError}
                isInvalid={Boolean(passwordError)}
                type={'password'}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
              />
            </Box>

            <Box pt={4} pb={8} w={'full'}>
              <Box h={8}>
                {error && (
                  <Text noOfLines={1} color={'red.300'} variant={'title_md'}>
                    {error}
                  </Text>
                )}
              </Box>
              <PrimaryButton
                disabled={isLoading}
                isLoading={isLoading}
                onClick={() => handleLogin()}
                title="Log in"
              />
            </Box>
          </FormControl>
          <Stack w={'full'} justifyContent={'space-between'} direction={'row'}>
            <Link to={'/forgot-password'}>
              <Text color={'blue.600'} variant="caption">
                Forgot password?
              </Text>
            </Link>
            <Box flex={1} />
            <Link to={'/signup'}>
              <Text color={'blue.600'} variant="caption">
                Don't have an account? Sign up
              </Text>
            </Link>
          </Stack>
        </Stack>
      </ModalContent>
    </Modal>
  );
}
