import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllActionsOf } from 'redux_store/utils/Actions';

interface SearchState {
  searchInput: string;
}

export const initialState: SearchState = {
  searchInput: ''
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    update(state, action: PayloadAction<string>) {
      state.searchInput = action.payload;
    }
  }
});

const { actions, reducer } = slice;

export type SearchActionsType = AllActionsOf<typeof actions>;
export { actions as searchActions, reducer as searchReducer };
