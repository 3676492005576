import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllActionsOf } from 'redux_store/utils/Actions';
import { AssetsInfo, Item, Collection } from 'services/api/sell/model';

const initialState: {
  nftListLoaded: boolean;
  inventory?: AssetsInfo[];
  inventoryMerge: AssetsInfo[];
  collection: Set<string>;
  collectionSet:  Map<string, Collection>;
  platforms: string[];
} = {
  nftListLoaded: true,
  inventoryMerge: Array.from(new Array(0)),
  collection: new Set(),
  collectionSet: new Map<string, Collection>,
  platforms: [],
};

const slice = createSlice({
  name: 'nftList',
  initialState,
  reducers: {
    updateStatus(state, actions: PayloadAction<boolean>) {
      state.nftListLoaded = actions.payload;
    },
    updateCollection(state, action: PayloadAction<Item[]>) {
      const uniqueCollectionsMap = new Map<string, Collection>();

      for (const item of action.payload) {
          const collection = item.token.collection;
          if (!uniqueCollectionsMap.has(collection.id)) {
              collection.count = 1;
              uniqueCollectionsMap.set(collection.id, collection);
          } else {
            const collectionTemp = uniqueCollectionsMap.get(collection.id)
            collectionTemp!.count++
            uniqueCollectionsMap.set(collection.id, collectionTemp!);
          }
      }
      state.collectionSet = uniqueCollectionsMap

    },
    writeInventory(state, action: PayloadAction<AssetsInfo[]>) {
      state.inventory = action.payload;
      
      const collectionNames = new Set(action.payload.map(item => item.collection_name));
      state.collection = collectionNames;
      if (state.nftListLoaded) {
        state.nftListLoaded = false;
        state.inventoryMerge = action.payload;
      } else {
        const inventoryMerge = [...state.inventoryMerge] as AssetsInfo[];

        const itemsId = inventoryMerge.map((nft) => {
          return `${nft.address}_${nft.token_id}`;
        });

        state.inventoryMerge = action.payload.map((nft) => {
          const index = itemsId.findIndex(
            (itemId) => itemId === `${nft.address}_${nft.token_id}`
          );

          const nftDataCopy = { ...nft };

          if (
            index !== undefined &&
            inventoryMerge &&
            inventoryMerge[index].offer
          ) {
            nftDataCopy.offer = inventoryMerge[index].offer;
            nftDataCopy.loading = false;
          }

          return nftDataCopy;
        });
      }
    },
    updateInventoryMerge(state, action: PayloadAction<AssetsInfo>) {
      const inventoryMerge = [...state.inventoryMerge] as AssetsInfo[];
      const allOffers = action.payload.availableOffers;


      if (allOffers) {
        const newPlatforms = new Set(allOffers.map((offer) => offer.platform));
        const platformsa = Array.from(newPlatforms);
        const platformsArray = [...state.platforms, ...platformsa];
        state.platforms = platformsArray;
      }
      const index = inventoryMerge?.findIndex(
        (nft) =>
          `${nft.address}_${nft.token_id}` ===
          `${action.payload.address}_${action.payload.token_id}`
      );

      if (index !== undefined && index !== -1 && state.inventoryMerge) {
        state.inventoryMerge[index] = action.payload;
      }
    }
  }
});

const { actions, reducer } = slice;

export type NftListActionsType = AllActionsOf<typeof actions>;
export { actions as nftListActions, reducer as nftListReducer };
