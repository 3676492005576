import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from 'redux_store/user/model';
import { AllActionsOf } from 'redux_store/utils/Actions';
import { Nft, OrderResponse } from 'services/api/nft/model';
import { User } from 'services/api/user/model';

export const initialState: UserState = {
  isAuthSuccess: false,
  ownedItems: [],
  inProgressItems: [],
  listedItems: [],
  favourites: [],
  isItemsLoading: false,
  isUserProfileLoaded: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // eslint-disable-next-line 
    getUserProfile() {},
    getUserProfileSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isAuthSuccess = true;
      state.isUserProfileLoaded = true;
    },
    getUserProfileFailed(
      state,
      action: PayloadAction<{ shouldLogout?: boolean }>
    ) {
      if (action.payload.shouldLogout) {
        state.user = undefined;
        state.isAuthSuccess = false;
      }
    },
    authSuccess(state) {
      state.isAuthSuccess = true;
    },
    // eslint-disable-next-line 
    getUserItems(state) {
      state.isItemsLoading = true;
    },
    getOwnedItemsSuccess(state, action: PayloadAction<OrderResponse[]>) {
      state.ownedItems = action.payload;
      state.isItemsLoading = false;
    },
    getListedItemsSuccess(state, action: PayloadAction<OrderResponse[]>) {
      state.listedItems = action.payload;
      state.isItemsLoading = false;
    },
    getInProgressItemsSuccess(state, action: PayloadAction<OrderResponse[]>) {
      state.inProgressItems = action.payload;
      state.isItemsLoading = false;
    },
    getUserFailed(state) {
      state.isItemsLoading = false;
    },

    // eslint-disable-next-line 
    getUserFavorite() {},
    // eslint-disable-next-line 
    getUserFavoriteSuccess(state, action: PayloadAction<Nft[]>) {
      state.favourites = action.payload;
    },
    logout(state) {
      state.user = undefined;
      state.isAuthSuccess = false;
    }
  }
});

const { actions, reducer } = slice;
export type UserActionsType = AllActionsOf<typeof actions>;
export { actions as userActions, reducer as userReducer };
