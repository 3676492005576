import { Modal, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux_store/rootReducer';

export default function LoadingModal() {
  const { isLoading } = useSelector((s: RootState) => s.overlay);

  return (
    <Modal
      size={'xs'}
      isCentered={true}
      onClose={() => console.log()}
      isOpen={isLoading}
    >
      <ModalOverlay background={'transparent'} />
      <ModalContent
        alignItems={'center'}
        py={8}
        background={'rgba(255, 255, 255, 0.8)'}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="purple.500"
          size="xl"
        />
      </ModalContent>
    </Modal>
  );
}
