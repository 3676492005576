import { Box } from '@chakra-ui/react';
import {
  ComingSoonModal,
  Footer,
  LoadingModal,
  LoginModal,
  NavBar
} from 'components/elements';
import { useGetETHPrice } from 'hooks/wallet/useGetEthPrice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import { RootState } from 'redux_store/rootReducer';
import { userActions } from 'redux_store/user/slice';
import { walletActions } from 'redux_store/wallet/slice';
import { RouteItem, routes } from 'router/routes';

export function BaseContainer() {
  const { isAuthSuccess, user, isUserProfileLoaded } = useSelector(
    (s: RootState) => s.user
  );
  const dispatch = useDispatch();
  const price = useGetETHPrice();

  useEffect(() => {
    if (isAuthSuccess) {
      dispatch(userActions.getUserProfile());
    }
  }, [isAuthSuccess, dispatch]);

  useEffect(() => {
    dispatch(walletActions.update(price));
  }, [dispatch, price]);

  useEffect(() => {
    if (user && isAuthSuccess && isUserProfileLoaded) {
      if (!user.email_verified && window.location.pathname !== '/verify') {
        window.location.pathname = '/verify';
      }
    }
  }, [user, isAuthSuccess, isUserProfileLoaded]);
  const location = useLocation();

  useEffect(() => {
    let matchedRoute: RouteItem | undefined;
    for (const n of [...routes]) {
      const match = matchPath(n.path, location.pathname);
      if (match) {
        matchedRoute = n;
        break;
      }
    }

    if (matchedRoute) {
      const titleDom = window.document.getElementById('app-title');

      if (titleDom) {
        titleDom.innerText = `Highswap - ${matchedRoute.title}`;
      }
    }
  }, [location.pathname]);

  return (
    <div className="bg-[#17171D] font-body text-jacarta-500">
      <Routes>
        {routes.map((route) => {
          const el = route.element;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Box bgColor="#17171D">
                  <NavBar />
                  <Box
                    bgColor="#17171D"
                    pt={'86px'}
                    minHeight="calc(100vh - 146px)"
                  >
                    {el}
                  </Box>
                  <Footer />
                </Box>
              }
            />
          );
        })}
      </Routes>
      <LoginModal />
      <LoadingModal />
      <ComingSoonModal />
    </div>
  );
}
