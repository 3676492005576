import { BackgroundProps, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useScreen from 'hooks/useScreen';
import useScroll from 'hooks/useScroll';
import React from 'react';

type ScrollBackgroundProviderProps = {
  bgImages?: Array<BackgroundProps['bgImage']>;
} & React.ComponentProps<'div'>;

const ScrollBackgroundContainer = styled.div`
  width: 100%;
  z-index: 1;

  > * {
    z-index: 10;
  }
`;

const ScrollBackgroundProvider = (props: ScrollBackgroundProviderProps) => {
  const [indexOfImage, setIndexOfImage] = React.useState(0);
  const screen = useScreen();
  const scroll = useScroll();

  const lenImages = props.bgImages ? props.bgImages.length : 0;

  React.useEffect(() => {
    const halfP = screen.height / 2;
    if (props.bgImages) {
      if (scroll.y >= 0 && scroll.y < halfP) {
        const i = Math.min(lenImages, 0);
        setIndexOfImage(i);
      }
      if (scroll.y >= halfP && scroll.y < screen.height * 1 + halfP) {
        const i = Math.min(lenImages, 1);
        setIndexOfImage(i);
      }
      if (
        scroll.y >= screen.height * 1 + halfP &&
        scroll.y < screen.height * 2 + halfP
      ) {
        const i = Math.min(lenImages, 2);
        setIndexOfImage(i);
      }
      if (scroll.y >= screen.height * 2 + halfP) {
        const i = Math.min(lenImages, 3);
        setIndexOfImage(i);
      }
    }
  }, [scroll, screen]);

  return (
    <React.Fragment>
      <Box
        bgImage={props.bgImages && props.bgImages[indexOfImage]}
        bgPosition={['top', 'top', 'top', 'center']}
        bgSize={['100% 50%', '100% 50%', '100% 50%', 'cover']}
        bgRepeat={'no-repeat'}
        className="fixed h-screen w-screen transition-all"
        zIndex={'-1'}
      ></Box>
      <ScrollBackgroundContainer>{props.children}</ScrollBackgroundContainer>
    </React.Fragment>
  );
};

export { ScrollBackgroundProvider };
