import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from 'redux_store/rootReducer';
import rootSaga from 'redux_store/rootSaga';
import { getPersistConfig } from 'redux-deep-persist';
import logger from 'redux-logger';
import {
  createMigrate,
  MigrationManifest,
  PersistedState,
  persistReducer,
  persistStore
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

const MIGRATION_DEBUG = false;

const sagaMiddleware = createSagaMiddleware();

const migrations: MigrationManifest = {
  1: (state) => {
    return {
      ...state
    } as unknown as PersistedState;
  }
};
// sellingCart
const persistConfig = getPersistConfig({
  key: 'root',
  version: 1,
  storage,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
  whitelist: ['user.user', 'user.isAuthSuccess', 'detail.detail',],
  rootReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, logger))
);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { persistor, store };
