import LooksrareIcon from './LooksrareIcon';
import NftxIcon from './NftxIcon';
import OpenseaIcon from './OpenseaIcon';
import SudoswapIcon from './SudoswapIcon';
import X2Y2Icon from './X2Y2Icon';

interface PlatformIconProps {
  size?: number | number[];
  type: 'SUDOSWAP' | 'OPENSEA' | 'LOOKSRARE' | string;
}

export const PlatformIcon = (props: PlatformIconProps) => {
  const { type, size = 30 } = props;

  return (
    <>
      {type === 'OPENSEA' && <OpenseaIcon fontSize={size} />}
      {type === 'SUDOSWAP' && <SudoswapIcon fontSize={size} />}
      {type === 'LOOKSRARE' && <LooksrareIcon fontSize={size} />}
      {type === 'X2Y2' && <X2Y2Icon fontSize={size} />}
      {type === 'NFTX' && <NftxIcon fontSize={size} />}
    </>
  );
};
