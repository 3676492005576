import { Box, Select, useBreakpointValue } from "@chakra-ui/react";
import { SortingOptions } from 'redux_store/filter/model';
import React from "react";


interface SortSectionProps {
  onSortChange: (selectedSortOption: SortingOptions) => void;
}

export default function SortSection({ onSortChange }: SortSectionProps) {
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = event.target.value as SortingOptions;
    onSortChange(selectedSortOption);
  };

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box position={"relative"} w={isDesktop ? "340px" : "full"}>
      <Select onChange={handleSortChange} w="200px" h= "48px" mr="4" color="rgba(173, 173, 177, 0.8)" borderColor="#404045" className="py-[0px]">
        <option value="">Sort by</option>
        <option value={SortingOptions.PriceHighLow}>Price high to low</option>
        <option value={SortingOptions.PriceLowHigh}>Price low to high</option>
        <option value={SortingOptions.AcquiredEarly}>Earliest acquired</option>
        <option value={SortingOptions.AcquiredLate}>Latest acquired</option>
      </Select>
    </Box>
  );
}