import { Badge, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';

interface AnimationProps {
  animation?: string;
  delay?: string;
}

export type NavTextBtnProps = {
  title: string;
  url: string;
} & AnimationProps;

const Root = styled.div<AnimationProps>`
  opacity: ${(p) => (p.animation ? '0' : '1')};
  animation: ${(p) => p.animation};
  animation-delay: ${(p) => p.delay};
  animation-fill-mode: forwards;
`;

const NavTextBtn = ({ title, url, animation, delay }: NavTextBtnProps) => {
  return (
    <Root animation={animation} delay={delay}>
      <Link to={url}>
        <Text
          fontWeight={'600'}
          color={'black'}
          px={5}
          minWidth={title === 'Liquidity Pool' ? '180px' : 'auto'}
          _hover={{ color: 'gray.400' }}
          variant="title_l"
        >
          {title}
          {title === 'Liquidity Pool' && (
            <Badge
              style={{ textTransform: 'lowercase', marginLeft: '0.5em' }}
              colorScheme="purple"
            >
              Coming soon
            </Badge>
          )}
        </Text>
      </Link>
    </Root>
  );
};

export default NavTextBtn;
