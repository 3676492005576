import { ChakraProvider } from '@chakra-ui/react';
import { BaseContainer } from 'components/containers/BaseContainer';
import { ComingSoonModalContext } from 'components/elements';
import { useComingSoonModalOpen } from 'components/elements/modal/ComingSoonModal';
import { Web3Provider } from 'context/wallet/provider';
import { CenterProvider } from 'nft-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from 'redux_store/configurePersistedStore';
import { PersistGate } from 'redux-persist/integration/react';
import { getCenterApiKey } from 'services/api/util';
import customTheme from 'theme';

export const queryClient = new QueryClient();

function App() {
  const {
    isOpen,
    handleSetIsOpen,
    code,
    setCode,
    isInvalid,
    setIsInvalid,
    setSubmitCallback,
    onSubmit,
    handleModalOpen
  } = useComingSoonModalOpen();

  return (
    <Web3Provider>
      <QueryClientProvider client={queryClient}>
        <CenterProvider apiKey={getCenterApiKey()}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <ChakraProvider theme={customTheme}>
                <ComingSoonModalContext.Provider
                  value={{
                    isOpen,
                    handleSetIsOpen,
                    code,
                    setCode,
                    isInvalid,
                    setIsInvalid,
                    setSubmitCallback,
                    onSubmit,
                    handleModalOpen
                  }}
                >
                  <BrowserRouter>
                    <BaseContainer />
                  </BrowserRouter>
                </ComingSoonModalContext.Provider>
              </ChakraProvider>
            </PersistGate>
          </Provider>
        </CenterProvider>
      </QueryClientProvider>
    </Web3Provider>
  );
}

export default App;
