import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoadingOverlayProps,
  ModalOverlayProps,
  OverlayState
} from 'redux_store/overlay/model';
import { AllActionsOf } from 'redux_store/utils/Actions';

export const initialState: OverlayState = {
  isLoading: false,
  isLoginOverlayOpen: false
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    updateLoadingOverlay(state, action: PayloadAction<LoadingOverlayProps>) {},
    toggleLoadingOverlay(state, action: PayloadAction<LoadingOverlayProps>) {
      state.isLoading = action.payload.isLoading;
    },
    toggleLoginOverlay(state, action: PayloadAction<ModalOverlayProps>) {
      state.isLoginOverlayOpen = action.payload.isOpen;
    }
  }
});

const { actions, reducer } = slice;
export type OverlayActionsType = AllActionsOf<typeof actions>;
export { actions as overlayActions, reducer as overlayReducer };
