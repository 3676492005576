import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, GridItem, Icon, Image, Text } from '@chakra-ui/react';
import noImage from 'assets/no-image.png';
import { utils } from 'ethers';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux_store/rootReducer';
import { formatPrice } from 'utils';

import WETHIcon from '../../../assets/icons/WETH.svg';
import { PlatformIcon } from '../icon/PlatformIcon';

interface CartItemProps {
  classname?: string;
  onDelete: (tokenId: string) => void;
  ETHPrice: number;
  tokenId: string;
  collectionName: string;
  platform: string;
  creatorFee: number;
  platformFee: number;
  price: string;
  deductionPrice: string;
}

export const CartItem = (props: CartItemProps) => {
  const { tokenId, deductionPrice, onDelete, ETHPrice, platform } = props;
  const [showDetails, setShowDetails] = useState(false);

  const { sellingCartItems } = useSelector((s: RootState) => s.sellingCart);

  const sellingItemInfo = useMemo(() => {
    return sellingCartItems.find((item) => item.itemId === tokenId);
  }, [sellingCartItems, tokenId]);

  return (
    <Box userSelect="none" paddingX="16px" marginY="12px">
      <Flex cursor="pointer" justifyContent="space-between">
        <Box position="relative" flexShrink={0} alignSelf="center">
          <Box overflow="hidden" borderRadius="8px" width="64px" height="64px">
            <Image
              transition={'transform 1s, -webkit-transform 1s'}
              loading={'lazy'}
              src={sellingItemInfo?.preview || noImage}
            />
          </Box>

          <Icon
            onClick={() => onDelete(tokenId)}
            width="16px"
            height="16px"
            backgroundColor="#FFFFFF"
            position="absolute"
            right="-5px"
            top="-5px"
            as={CloseIcon}
            borderRadius="50%"
            padding="3px"
            borderWidth="1px"
          />
        </Box>

        <Box marginLeft="12px" alignSelf="center">
          <PlatformIcon size={24} type={platform} />
        </Box>

        <Box
          onClick={() => setShowDetails(!showDetails)}
          paddingX="12px"
          alignSelf="center"
          flexGrow={1}
        >
          <Flex flexDirection="column">
            <Grid templateColumns="repeat(2, 1fr)">
              <GridItem overflow="hidden">
                <Text
                  className="line-clamp-2"
                  wordBreak="break-all"
                  fontSize="15"
                  fontWeight="bold"
                  color="#ffffff"
                >
                  {sellingItemInfo?.collection_name} #{tokenId?.split('_')[1]}
                </Text>
              </GridItem>

              <Flex justifyContent="flex-end">
                <Image
                  marginX="3px"
                  alignSelf="center"
                  width="18px"
                  src={WETHIcon}
                ></Image>
                <Text
                  alignSelf="center"
                  fontSize="18px"
                  fontWeight="700"
                  color="#ffffff"
                >
                  {formatPrice(deductionPrice || '0.00')}
                </Text>
              </Flex>
            </Grid>

            <Grid
              templateColumns="repeat(2, 1fr)"
              fontSize="12px"
              color="#DEDEE6"
            >
              <GridItem overflow="hidden">
                <Text className="truncate">
                  {sellingItemInfo?.collection_name}
                </Text>
              </GridItem>
              <Flex justifyContent="flex-end">
                $
                {(
                  ETHPrice *
                  Number(
                    utils.formatEther(
                      (deductionPrice || 0).toString().split('.')[0]
                    )
                  )
                ).toFixed(2)}
              </Flex>
            </Grid>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
