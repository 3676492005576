import { SpinnerIcon } from '@chakra-ui/icons';
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  Image,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import noImage from 'assets/no-image.png';
import cn from 'classnames';
import { SecondaryButton } from 'components/elements';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AssetsInfo } from 'services/api/sell/model';
import { formatPrice } from 'utils';

import WETHIcon from '../../../assets/icons/WETH.svg';
import { PlatformIcon } from '../icon/PlatformIcon';
import BestDealDetailsModal from '../modal/BestDealDetailsModal';
import BestDealModal from '../modal/BestDealModal';

export interface SellCardProps {
  item?: AssetsInfo;
  selected: boolean;
  onClick: (select: boolean) => void;
}

export default function SellCard(props: SellCardProps) {
  const { item, selected, onClick } = props;
  const bestDealDetailsModal = useDisclosure();
  const bestDealModal = useDisclosure();

  const isPriceAvaliable = useMemo(() => {
    if (item && item.offer && item.offer.price) {
      return true;
    }
    return false;
  }, [item]);

  const getOfferStatus = useMemo(() => {
    if (!item || item.loading) return 'loading';
    if (isPriceAvaliable) return 'completed';
    return 'failed';
  }, [isPriceAvaliable, item]);

  const handleClick = useCallback(() => {
    if (
      !item || !item.offer || !item.offer.price
    )
      return;

    if (!item.offer.usable) {
      bestDealModal.onOpen();
      return;
    }

    onClick(!selected);
  }, [bestDealModal, item, onClick, selected]);

  return (
    <Box className="w-[50%] lg:w-[50%] xl:w-[25%] xxl:w-[25%] max-w-[350px]">
      <Box
        onClick={handleClick}
        borderRadius={['9px', '9px', '12px']}
        className="relative ml-[14px] mt-[14px] flex-shrink-0 cursor-pointer transition-shadow hover:shadow-lg lg:ml-[18px] lg:mt-[18px]"
      >
        <Box
          className={cn(
            'block w-full rounded-[10px] border-[3px] p-[6px] lg:p-[12px]',
            getOfferStatus === 'failed' ||
              !item ||
              String(item?.address).toLowerCase() ===
                '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85'
              ? 'bg-[#47474D]'
              : 'bg-[#212124]',
            selected ? 'border-[#164AFF]' : 'border-[#404045]'
          )}
        >
          <figure className="relative select-none">
            <AspectRatio rounded={'8px'} w="full" overflow={'hidden'} ratio={1}>
              <>
                {!item && (
                  <Skeleton
                    fadeDuration={1}
                    startColor="#313136"
                    endColor="#373738"
                  >
                    <Image src={noImage} />
                  </Skeleton>
                )}
                {item && (
                  <Image src={item?.small_preview_image_url || noImage} />
                )}
              </>
            </AspectRatio>

            <Box className="absolute top-1 right-0 lg:top-3 lg:right-3">
              <SkeletonCircle color="#3E3E41" isLoaded={!!item}>
                {item && (
                  <PlatformIcon size={[20, 30]} type={item.offer?.platform} />
                )}
              </SkeletonCircle>
            </Box>

            {selected && (
              <Box className="absolute top-0 left-0 z-10 flex h-full w-full items-center justify-center">
                <Image src={require('assets/selected@2x.png')} h={'45px'} />
              </Box>
            )}
          </figure>

          {item ? (
            <Box
              mt={2}
              alignItems={'center'}
              justifyContent={'between'}
              mb={[1, 2]}
            >
              <a className="block">
                <span className="block w-[full] select-none overflow-hidden text-ellipsis whitespace-nowrap font-display text-[12px] text-white lg:text-[18px]">
                  {item.collection_name} #{item.token_id}
                </span>
              </a>
            </Box>
          ) : (
            <Skeleton
              startColor="#313136"
              endColor="#373738"
              isLoaded={!!item}
              height="20px"
              width="60%"
              marginTop="12px"
              rounded="4px"
            ></Skeleton>
          )}

          {item ? (
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              {renderPrice(item, getOfferStatus)}

              <div
                className={
                  String(item?.address).toLowerCase() ===
                  '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85'
                    ? 'hidden'
                    : ''
                }
              >
                <Link to={`/detail/${item?.address}/${item?.token_id}`} onClick={(e) => e.stopPropagation()}>
                  <SecondaryButton
                    size="sm"
                    disabled={getOfferStatus !== 'completed'}
                    title="Details"
                  />
                </Link>
              </div>
            </Flex>
          ) : (
            <Skeleton
              startColor="#313136"
              endColor="#373738"
              height="20px"
              width="40%"
              marginTop="12px"
              rounded="4px"
            ></Skeleton>
          )}
        </Box>

        <Box
          hidden={getOfferStatus !== 'failed'}
          rounded="12px"
          position="absolute"
          zIndex={1}
          left={0}
          top={0}
          width="100%"
          height="100%"
          backgroundColor="rgba(0,0,0,0.15)"
        ></Box>
      </Box>

      {item && bestDealDetailsModal.isOpen && (
        <BestDealDetailsModal
          itemId={`${item.address}_${item.token_id}`}
          collection_name={item.collection_name}
          preview={item.small_preview_image_url}
          isOpen={bestDealDetailsModal.isOpen}
          onClose={bestDealDetailsModal.onClose}
        />
      )}

      {item && bestDealModal.isOpen && (
        <BestDealModal
          itemId={`${item.address}_${item.token_id}`}
          collection_name={item.collection_name}
          preview={item.small_preview_image_url}
          isOpen={bestDealModal.isOpen}
          onClose={bestDealModal.onClose}
          onConfirm={() => {
            onClick(!selected);
            bestDealModal.onClose();
          }}
        />
      )}
    </Box>
  );

  function renderPrice(item: AssetsInfo, status: string) {
    if (
      String(item?.address).toLowerCase() ===
      '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85'
    ) {
      return (
        <Stack spacing={0} direction={'column'}>
          <Stack spacing={0} align="center" direction={'row'}>
            <Text
              textOverflow="ellipsis"
              fontFamily={'Space Grotesk'}
              variant="title_m"
              color={'gray'}
              marginY={'1.5'}
              fontWeight={'700'}
              fontSize={['14px', '16px']}
            >
              Item is not supported
            </Text>
          </Stack>
        </Stack>
      );
    }

    if (status === 'loading') {
      return (
        <Icon alignSelf="center" className="animate-spin" as={SpinnerIcon} />
      );
    }

    if (status === 'completed') {
      return (
        <Stack spacing={0} direction={'column'}>
          <Stack spacing={0} align="center" direction={'row'}>
            <Image
              alignSelf="center"
              width={['16px', '24px']}
              src={WETHIcon}
            ></Image>

            <Text
              textOverflow="ellipsis"
              fontFamily={'Space Grotesk'}
              variant="title_m"
              color={'#FFFFFF'}
              fontWeight={'700'}
              fontSize={['14px', '16px']}
            >
              {formatPrice(item.offer.price)}
            </Text>
          </Stack>
        </Stack>
      );
    }

    return (
      <Text
        variant="title_m"
        color={'#FFFFFF'}
        fontWeight={'700'}
        fontSize={'16px'}
      >
        N/A
      </Text>
    );
  }
}
