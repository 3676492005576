import {
  Box,
  Stack,
  Flex,
  Text,
  Image,
  useBreakpointValue,
  // useInterval,
  useLatestRef,
  useToast
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { FilterSection, PaginationSection, HowItWorkButton, SearchSection, SortSection, SellCard } from 'components/elements';
import { SellingCart } from 'components/elements/sellingCart/card';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { nftListActions } from 'redux_store/nftList/slice';
import { filterActions } from 'redux_store/filter/slice';
import { RootState } from 'redux_store/rootReducer';
import { SellingCartItem } from 'redux_store/sellingCart/model';
import { sellingCartActions } from 'redux_store/sellingCart/slice';
import { paginationActions } from 'redux_store/pagination/slice';
import { getNFTOffers } from 'services/api/offer';
import { getAssetsOwned } from 'services/api/sell';
import { AssetsInfo } from 'services/api/sell/model';
import { SortingOptions } from 'redux_store/filter/model';

import filter from '../../assets/icons/filter.png';

export default function Inventory() {
  const toast = useToast();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const [walletAddress, setWalletAddress] = useState<
    string | null | undefined
  >();
  const [inventoryItems, setInventoryItems] = useState<AssetsInfo[]>([]);
  const [cuurentPageItems, setCurrentItems] = useState<AssetsInfo[]>([]);
  const [filterShow, setFilterShow] = useState<Boolean>(false);

  const params = useParams();
  const [refresh, setRefresh] = useState(0);


  const { sellingCartItems, isOpenSellCard } = useSelector(
    (s: RootState) => s.sellingCart
  );

  const { collectionFilter, platformFilter, filterOffer, maxprice, minprice } = useSelector(
    (s: RootState) => s.filter
  );

  const { searchInput } = useSelector((s: RootState) => s.search);

  const { nftListLoaded, inventory, inventoryMerge } = useSelector(
    (s: RootState) => s.nftList
  );

  const { totalItems, currentPage, pageSize } = useSelector(
    (s: RootState) => s.pagination
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      setWalletAddress(account);
    } else {
      if (Object.keys(params).length === 0) {
        setWalletAddress(account);
      } else {
        setWalletAddress(params.address);
      }
    }
  }, [account, params]);


  useQuery(
    ['getInventory', walletAddress],
    async () => {
      if (!walletAddress) return;
      const [assets, items] = await getAssetsOwned(walletAddress);
      dispatch(nftListActions.updateCollection(items));
      return assets;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        // async function processQueue(data: AssetsInfo[], chunkSize = 32) {
        //   for (let i = 0; i < data.length; i += chunkSize) {
        //     const chunk = data.slice(i, i + chunkSize);
        //     await getNFTCollectionPrice(chunk);
        //   }
        // }
        
        if (!response) return;
        const processData = response.map((item) => {
          return {
            ...item,
            loading: true
          };
        });
        if (totalItems === processData.length) {
          return;
        }                 
        
        dispatch(paginationActions.setTotalItems(processData.length));
        dispatch(nftListActions.updateStatus(true));
        dispatch(nftListActions.writeInventory(processData));
        
        if (searchInput) {
          const searchData = processData.filter(
            (itemData) =>                                
              `${itemData.collection_name} #${itemData.token_id}`
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) !== -1
          );
          const [paginatedData, _] = processPagination(searchData);
          await getNFTCollectionPrice(paginatedData);
          // processQueue(notPaginatedData, 32);
          return;
        } else {
          const [paginatedData, _] = processPagination(processData);
          await getNFTCollectionPrice(paginatedData);
          // processQueue(notPaginatedData, 32);
        }
        
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Fetch nfts failed',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
  );


  // Initial get and refresh inventory
  useQuery(
    ['getInventory',refresh, searchInput, currentPage],
    async () => {
      if (!walletAddress) return;
      const [assets, items] = await getAssetsOwned(walletAddress);
      dispatch(nftListActions.updateCollection(items));
      return assets;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: async (response) => {
        // async function processQueue(data: AssetsInfo[], chunkSize = 32) {
        //   for (let i = 0; i < data.length; i += chunkSize) {
        //     const chunk = data.slice(i, i + chunkSize);
        //     await getNFTCollectionPrice(chunk);
        //   }
        // }
        
        if (!response) return;
        const processData = response.map((item) => {
          return {
            ...item,
            loading: true
          };
        });                      
        
        dispatch(paginationActions.setTotalItems(processData.length));
        dispatch(nftListActions.updateStatus(true));
        
        if (searchInput) {
          const searchData = processData.filter(
            (itemData) =>                                
              `${itemData.collection_name} #${itemData.token_id}`
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) !== -1
          );
          const [paginatedData, _] = processPagination(searchData);
          await getNFTCollectionPrice(paginatedData);
          // processQueue(notPaginatedData, 32);
          return;
        } else {
          const [paginatedData, _] = processPagination(processData);
          console.log('processData ', processData)
          await getNFTCollectionPrice(paginatedData);
          // processQueue(notPaginatedData, 32);
        }
        
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Fetch nfts failed',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
  );
  
  const processPagination = (processData : AssetsInfo[]) => {
    const paginatedData = processData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const beforePaginatedData = processData.slice(0, currentPage * pageSize);
    const afterPaginatedData = processData.slice((currentPage + 1) * pageSize);
    const notPaginatedData = beforePaginatedData.concat(afterPaginatedData);
    return [paginatedData, notPaginatedData];
  };



  useEffect(() => {
    const [paginatedData, _] = processPagination(inventoryItems);
    setCurrentItems(paginatedData);
  }, [inventoryItems, currentPage, pageSize]);


  // sorting functions for inventory page.
  useEffect(() => {
    if (inventoryMerge) {
      let filteredInventory = (inventoryMerge as AssetsInfo[]).filter((item) => item !== undefined);

      if (maxprice || minprice) {

        filteredInventory = filteredInventory.filter((item) => {
          const price = parseInt(item.offer?.price) ?? 0; 
          if (maxprice === 0 || !maxprice) {
            return price >= minprice; // Only minprice specified
          } else if (minprice === 0 || !minprice ) {
            return price <= maxprice; // Only maxprice specified
          } else {
            return price >= minprice && price <= maxprice; // Both minprice and maxprice specified
          }
        });
      }

      if (collectionFilter.length > 0) {
        filteredInventory = filteredInventory.filter((item) => {
          return collectionFilter.includes(item.collection_name)
        });
      }
      
      if (platformFilter.length > 0) {
        filteredInventory = filteredInventory.filter((item) => {
          const filteredOffers = item.availableOffers?.filter((fitem) => {
            return platformFilter.includes(fitem.platform);
          });
      
          return filteredOffers && filteredOffers.length > 0;
        });
      }

      setInventoryItems(filteredInventory);
    }
  }, [inventoryMerge, collectionFilter, platformFilter, maxprice, minprice]);

  useEffect(() => {
    const filteringItem = inventoryMerge;
    if (filterOffer) {
      const filteredInventory = (filteringItem as AssetsInfo[]).filter((item) => item.offer !== undefined);
      setInventoryItems(filteredInventory);
    } else {
      const filteredInventory = (inventoryMerge as AssetsInfo[]).filter((item) => item !== undefined);
      setInventoryItems(filteredInventory);
    }
  }, [filterOffer]);
  


  const getNFTCollectionPrice = async (
    inventory: AssetsInfo[],
  ) => {
    const formattedData: { [key: string]: { nft: string[] } } = {};

    inventory.forEach((item) => {
      const { address, token_id } = item
      if (!formattedData[address]) {
        formattedData[address] = {
          nft: [token_id]
        };
      } else {
        formattedData[address].nft.push(token_id)
      }
    })

    const res = await getNFTOffers(formattedData);

    // if (!res.data.usable) return;
    inventory.forEach((element) => {
      const index = res[element.address]['nfts'].findIndex(obj => obj.hasOwnProperty(element.token_id));
      const nftData = element;
      const newNftData = { ...nftData };
      const highestPriceObject = res[element.address]['offers'].reduce((max, obj) => parseFloat(obj.price) > parseFloat(max.price) ? obj : max, res[element.address]['offers'][0]);
      if (index > -1 && res[element.address]['nfts'][index][nftData.token_id].length > 0) {
        const nftId = nftData.token_id;
        const newNftData = { ...nftData };
        const highestPrice = res[element.address]['nfts'][index][nftId].reduce((max, obj) => parseFloat(obj.price) > parseFloat(max.price) ? obj : max, res[element.address]['nfts'][index][nftId][0]);
    

        const finalHighestPrice = Math.max(parseFloat(highestPriceObject.price), parseFloat(highestPrice.price));

        let highestPriceObjectFinal;

        if (finalHighestPrice === parseFloat(highestPriceObject.price)) {
          highestPriceObjectFinal = highestPriceObject;
        } else {
          highestPriceObjectFinal = highestPrice;
        }

        if (res[element.address]['nfts'][index][nftId].find(item => item.platform !== highestPriceObject.platform)) {
          newNftData.availableOffers = [highestPriceObject, ...res[element.address]['nfts'][index][nftId]];
        } else {
          newNftData.availableOffers = res[element.address]['nfts'][index][nftId];
        }

        newNftData.offer = highestPriceObjectFinal;
        newNftData.loading = false;
        dispatch(nftListActions.updateInventoryMerge(newNftData));
      } else {
        

        if (highestPriceObject) {
          newNftData.offer = highestPriceObject;
          newNftData.availableOffers = [highestPriceObject]
        }

        newNftData.loading = false;
        dispatch(nftListActions.updateInventoryMerge(newNftData));

      }
    });
  };

  const toggle = useCallback(
    (selected: boolean, nftItem: SellingCartItem) => {
      if (selected) {
        if (sellingCartItems.length >= 1) {
          toast({
            title: 'Error',
            description: 'Exceeding the maximum sale limit',
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          return;
        }
        dispatch(sellingCartActions.add(nftItem));
        dispatch(sellingCartActions.updateIsOpenSellCard(true));
      } else {
        dispatch(sellingCartActions.delete(nftItem.itemId));
      }
    },
    [dispatch, sellingCartItems.length, toast]
  );

  const toggleFilter = () => {
    const filterStat = filterShow;
    setFilterShow(!filterStat)
  };

  const clearFilter = () => {
    dispatch(filterActions.clearFilter());
  }

  const handleSortChange = (selectedSortOption: SortingOptions) => {
    const sortingData = inventoryItems;
    switch (selectedSortOption) {
      case SortingOptions.PriceHighLow:
        const sortHigh = [...sortingData].sort((a, b) => {
          const aPrice = parseInt(a.offer?.price || "0");
          const bPrice = parseInt(b.offer?.price || "0");
          return bPrice - aPrice;
        })
        setInventoryItems(sortHigh);
        break;
      case SortingOptions.PriceLowHigh:
        const sortLow = [...sortingData].sort((a, b) => {
          const aPrice = parseInt(a.offer?.price || "0");
          const bPrice = parseInt(b.offer?.price || "0");
          return aPrice - bPrice;
        })
        setInventoryItems(sortLow);
        break;
      case SortingOptions.AcquiredEarly:
        const sortEarly = [...sortingData].sort((a, b) => {
          const date1 = new Date(a.acquired || "0");
          const date2 = new Date(b.acquired || "0");
          return date1.getTime() - date2.getTime();
        });
        setInventoryItems(sortEarly);
        break;
      case SortingOptions.AcquiredLate:
        const sortLate = [...sortingData].sort((a, b) => {
          const date1 = new Date(a.acquired || "0");
          const date2 = new Date(b.acquired || "0");
          return date2.getTime() - date1.getTime();
        });
        setInventoryItems(sortLate);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box w="full">
        <Box
          w={'full'}
          minHeight="calc(100vh - 232px)"
          className="relative flex justify-between"
        >
          <Box
            overflow="auto"
            className="relative w-full border-r-[1px] border-[#404045] px-[16px] md:px-10"
          >
            <Stack
              className="mb-5 items-start justify-between pt-8"
              flexDirection={['row']}
            >
              <Text variant="heading" color={'white'} fontWeight={'bold'}>
                My NFTs
              </Text>
            </Stack>
            <Flex
              className="mb-5 items-start justify-left gap-[8px] pt-8"
              flexDirection={['row']}
            >
              <Flex flexShrink="0" alignItems='center' justifyContent='left' className="w-[250px] h-[48px] direction-row p-[4px] gap-[8px]">
                <Flex alignItems='center' borderRadius='8px' backgroundColor={filterShow ? '#164AFF' : ''} className="w-[48px] h-[48px] direction-row justify-left p-[4px] gap-[8px] border-[#ffffff]">
                  <Image
                    w="24px"
                    h="24px"
                    m="0 auto"
                    cursor="pointer"
                    src={filter}
                    onClick={toggleFilter}
                  />
                </Flex>
                <Text variant="heading" color={'white'} fontSize={'20px'} fontWeight={'bold'}>
                  Filter
                </Text>
                <Box flexGrow="1"></Box>
                {filterShow && 
                  <Text cursor={'pointer'} onClick={clearFilter} variant="heading" color={'#ADADB1'} fontSize={'12px'} fontWeight={'normal'}>
                    Clear all &#8201; &#10005;
                  </Text>
                }
              </Flex>
              {/* <Text variant="caption">Updated 5s ago</Text> */}
              <SearchSection />
              {inventoryItems?.length > 0 && <SortSection onSortChange={handleSortChange}/>}
            </Flex>


            <Flex flexDirection={isDesktop ? 'row' : 'column'}>
              {filterShow && <FilterSection filterShow={filterShow} /> }
              {cuurentPageItems?.length === 0 ? (
                <Text pl={2}>No inventory in your wallet</Text>
              ) : (
                <Flex flexDirection={'column'} paddingBottom={"10px"}  width={'100%'}>
                  <Flex  flexGrow="1" className="mb-10 flex flex-wrap flex-basis-auto">
                    {cuurentPageItems.map((n, index) => {

                      return (
                        <SellCard
                          item={n}
                          key={index}
                          selected={sellingCartItems
                            .map((item: { itemId: any; }) => item.itemId)
                            .includes(`${n?.address}_${n?.token_id}`)}
                          onClick={(selected) => {
                            if (!n || 
                              !(n.offer && n.offer.price)) return;
                            toggle(selected, {
                              itemId: `${n.address}_${n.token_id}`,
                              preview: n.small_preview_image_url,
                              collection_name: n.collection_name,
                              price: n.offer?.price,
                              platform: n.offer?.platform,
                              creatorFee: n.offer?.creatorFee,
                              platformFee: n.offer?.platformFee,
                              deductionPrice: n.offer?.deductionPrice,
                              hash: n.offer?.hash,
                              type: n.offer.type,
                              protocolAddress: n.offer.protocolAddress,
                              maker: n.offer.maker,
                            });
                          }}
                        />
                      );
                    })}
                  </Flex>
                  <PaginationSection />
                </Flex>
              )}
            </Flex>
          </Box>

          <Box
            hidden={!isOpenSellCard}
            position={['fixed', 'fixed', 'relative']}
            flexShrink={0}
            width={['full', 'full', '370px', '410px', '450px', '500px']}
            bottom={['0', '0', 'unset']}
            zIndex={10}
          >
            <Box
              className="px-[16px] pt-[16px] lg:block lg:px-[18px] lg:pt-[18px]"
              position={['absolute', 'absolute', 'fixed']}
              width={['full', 'full', '370px', '410px', '450px', '500px']}
              bottom={['14px', '14px', 'unset']}
            >
              <SellingCart
                onRefresh={() => {
                  setTimeout(() => {
                    setRefresh(Math.random());
                  }, 2000);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
