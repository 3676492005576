import {
  collectionActions,
  CollectionActionsType
} from 'redux_store/collection/slice';
import * as Effects from 'redux-saga/effects';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { getCollections } from 'services/api/collection';
import { Contract } from 'services/api/collection/model';

const call: any = Effects.call;

function* handleGetBoxDetail(action: CollectionActionsType) {
  try {
    const data: Contract[] = yield call(getCollections);
    yield put(collectionActions.getUserCollectionsSuccess(data));
  } catch (e) {
    yield put(collectionActions.getUserCollectionsFailed(e));
  }
}

function* watchGetCollections() {
  yield takeLatest(
    collectionActions.getUserCollections.type,
    handleGetBoxDetail
  );
}

const saga = [fork(watchGetCollections)];

export default saga;
