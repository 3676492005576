import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const customTheme = extendTheme(
  {
    components: {
      Text: {
        variants: {
          title_l: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: 'l'
          },
          title_md: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: 'md'
          },
          title_sm: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: 'sm',
            fontWeight: 'medium'
          },
          title_xs: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: 'xs',
            fontWeight: 'medium'
          },
          title_xl: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: 'xl'
          },
          title_2xl: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: '2xl'
          },
          title_3xl: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: '3xl',
            fontWeight: 'bold'
          },
          heading: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: '4xl',
            fontWeight: 'bold'
          },
          caption: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: 'sm',
            color: 'gray.500',
            fontWeight: 'thin'
          },
          caption2: {
            fontFamily: [`"ChakraPetch-Regular", "sans-serif"`],
            fontSize: 'xs',
            color: 'gray.500',
            fontWeight: 'thin'
          }
        }
      }
    }
  },
  withDefaultColorScheme({ colorScheme: 'brand' })
);

export default customTheme;
