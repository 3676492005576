import { Input, InputGroup, Text } from '@chakra-ui/react';
import React from 'react';

export interface CustomInputProps {
  value?: string | number;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onInput?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  color?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  step?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  defaultValue?: string;
  placeHolderColor?: string;
  pr?: string;
  borderColor?: string;
}
export default function CustomInput({
  placeholder,
  value,
  disabled = false,
  onChange,
  type,
  leftElement,
  rightElement,
  color,
  isInvalid,
  errorMessage,
  isRequired,
  step,
  onBlur,
  onFocus,
  defaultValue,
  placeHolderColor,
  pr,
  borderColor,
  onInput
}: CustomInputProps) {
  return (
    <>
      <InputGroup>
        {leftElement}
        <Input
          disabled={disabled}
          pr={pr}
          onFocus={onFocus}
          borderColor={borderColor}
          defaultValue={defaultValue}
          onBlur={onBlur}
          step={step}
          isRequired={isRequired}
          size={'lg'}
          isInvalid={isInvalid}
          errorBorderColor="red.300"
          _placeholder={{
            color: placeHolderColor || '#2B2731',
            fontSize: '16px',
            fontWeight: '400'
          }}
          focusBorderColor="white"
          color={color || '#ADADB1'}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onInput={onInput}
        />
        {rightElement}
      </InputGroup>
      {isInvalid && (
        <Text pt={1} variant="caption2" color={'red.400'}>
          {errorMessage}
        </Text>
      )}
    </>
  );
}
