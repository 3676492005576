import React from 'react';
import { Contract } from 'services/api/collection/model';

import CollectionCard from './CollectionCard';

export interface CollectionArticleProps {
  data: Contract[];
}
const CollectionArticles = ({ data }: CollectionArticleProps) => {
  return (
    <>
      {data.map((item, _) => (
        <CollectionCard
          href={`/collections/${item.slug}`}
          key={item.slug}
          imgMain={item.image_url}
          description={
            item.description || "This is contract's description here"
          }
          // owner={item.by}
          name={item.name}
          // avatar={item.imgOwner}
        />
      ))}
    </>
  );
};
export default CollectionArticles;
