import styled from '@emotion/styled';
import React from 'react';

type Layer = 'base' | 'back' | 'deep-back';

type ParallexLayerProps = {
  layer?: Layer;
} & React.ComponentProps<'div'>;

const getZ = (layer: Layer) => {
  switch (layer) {
    case 'base':
      return '0';
    case 'back':
      return '-1px';
    case 'deep-back':
      return '-2px';
  }
};

const getScale = (layer: Layer) => {
  switch (layer) {
    case 'base':
      return '1';
    case 'back':
      return '2';
    case 'deep-back':
      return '3';
  }
};

const getZIndex = (layer: Layer) => {
  switch (layer) {
    case 'base':
      return '100';
    case 'back':
      return '4';
    case 'deep-back':
      return '3';
  }
};

const ParallexLayerRoot = styled.div<ParallexLayerProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(${(p) => p.layer && getZ(p.layer)})
    scale(${(p) => p.layer && getScale(p.layer)});
  z-index: ${(p) => p.layer && getZIndex(p.layer)};
`;

const ParallexLayer = (props: ParallexLayerProps) => {
  return (
    <ParallexLayerRoot {...props} role="layer">
      {props.children}
    </ParallexLayerRoot>
  );
};

export { ParallexLayer };
