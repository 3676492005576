import { BackgroundProps, Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

type ScrollWrapperProps = {
  bgImage?: BackgroundProps['bgImage'];
} & React.ComponentProps<'div'>;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  > * {
    flex: 1;
    z-index: 1;
  }
`;

const ScrollWrapper = (props: ScrollWrapperProps) => {
  // const childrenRef = React.useRef([]);

  // const c = React.Children.map(props.children, (child, index) =>
  //   React.cloneElement(child, {
  //     ref: (ref: any) => (childrenRef.current = ref),
  //   })
  // );

  // const h = childrenRef.current[0].getBoundingClientRect().height;

  return (
    <Box
      bgImage={props.bgImage}
      bgPosition={['top', 'top', 'top', 'center']}
      bgSize={['100% 50%', '100% 50%', '100% 50%', 'cover']}
      bgRepeat={'no-repeat'}
      className="h-screen w-screen"
      minH={['1322px', '1322px', '1322px', 'unset']}
    >
      <Wrapper>{props.children}</Wrapper>
    </Box>
  );
};

export { ScrollWrapper };
