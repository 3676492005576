import { Box, Text } from '@chakra-ui/react';

import { CartIcon } from '../icon/CartIcon';

export const EmptyCart = () => {
  return (
    <Box className="flex justify-center py-[50px]">
      <div className="self-center text-center">
        <CartIcon fontSize="56px" />

        <Text color="#49494B" fontWeight={'bold'} className="mt-5">
          Cart is empty
        </Text>
      </div>
    </Box>
  );
};
