import { LoadingOverlayProps } from 'redux_store/overlay/model';
import {
  overlayActions,
  OverlayActionsType
} from 'redux_store/overlay/overlay.slice';
import { delay, fork, put, takeLatest } from 'redux-saga/effects';

function* handleUpdateOverlay(action: OverlayActionsType) {
  // debounce by 500ms
  yield delay(500);
  yield put(
    overlayActions.toggleLoadingOverlay(action.payload as LoadingOverlayProps)
  );
}

function* watchUpdateOverlay() {
  yield takeLatest(
    overlayActions.updateLoadingOverlay.type,
    handleUpdateOverlay
  );
}

const saga = [fork(watchUpdateOverlay)];

export default saga;
