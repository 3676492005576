import axios, { AxiosResponse } from 'axios';

import { NFTOffer, NFTOfferResponse } from './model';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getNFTOffers = async (
  formattedData: any
): Promise<NFTOfferResponse> => {
  const res = await axiosInstance.post(
    `/v1/offer/new`,
    formattedData,
    {}
  );
  return res.data.result;
};

export const getCollectionOffers = async (
  formattedData: any
): Promise<NFTOfferResponse> => {
  const res = await axiosInstance.post(
    `/v1/offer/collection`,
    formattedData,
    {}
  );
  return res.data.result;
};

export const getItemOffers = async (
  formattedData: any
): Promise<NFTOfferResponse> => {
  const res = await axiosInstance.post(
    `/v1/offer/item`,
    formattedData,
    {}
  );
  return res.data.result;
};

export const getNFTOffersInCart = async (tokenIds: string, account: string, itemId: string, type: string) => {
  const { data } = await axiosInstance.get<any>(
    `/v1/offer/fulfill/${tokenIds}/${account}/${itemId}/${type}`,
  );

  return data;
};

export const refreshOffers = (collectionAddress: string) => {
  return axiosInstance.post<string>(
    `/cedar/v1/refresh/collection/${collectionAddress}`
  );
};

export const getBestOfferInEachPlatform = (itemId: string) => {
  return axiosInstance.get<NFTOffer[]>(`/cedar/v1/offer/${itemId}/each`);
};

export const getMoreBestOfferInEachPlatform = (itemId: string) => {
  return axiosInstance.get<NFTOffer[]>(`/cedar/v1/offer/${itemId}/more`);
};
