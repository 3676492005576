import {
  AspectRatio,
  Box,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import noImage from 'assets/no-image.png';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getBestOfferInEachPlatform } from 'services/api/offer';
import { NFTOffer, Platform, platforms } from 'services/api/offer/model';
import { formatPrice } from 'utils';

import loadingIcon from '../../../assets/icons/loadingIcon.svg';
import { PlatformIcon } from '../icon/PlatformIcon';

interface BestDealDetailsModalProps {
  className?: string;
  itemId: string;
  preview: string;
  collection_name: string;
  isOpen: boolean;
  onClose: () => void;
}

const platformTemplate = (platform: Platform) => {
  switch (platform) {
    case Platform.OPENSEA:
      return {
        platform,
        name: 'OpenSea',
        deductionPrice: '-',
        price: '-',
        platformFee: 250,
        creatorFee: '-'
      };
    case Platform.LOOKSRARE:
      return {
        platform,
        name: 'LooksRare',
        deductionPrice: '-',
        price: '-',
        platformFee: 500,
        creatorFee: '-'
      };
    case Platform.X2Y2:
      return {
        platform,
        name: 'X2Y2',
        deductionPrice: '-',
        price: '-',
        platformFee: 50,
        creatorFee: '-'
      };
    case Platform.SUDOSWAP:
      return {
        platform,
        name: 'sudoswap',
        deductionPrice: '-',
        price: '-',
        platformFee: 'Depends',
        creatorFee: '-'
      };
    case Platform.NFTX:
      return {
        platform,
        name: 'NFTx',
        deductionPrice: '-',
        price: '-',
        platformFee: 'Depends',
        creatorFee: '-'
      };
  }
};

export const toPercent = (point: number | '-') => {
  if (typeof point === 'string') return point;

  let str = Number(point / 100).toFixed(2);
  str += '%';
  return str;
};

export default function BestDealDetailsModal(props: BestDealDetailsModalProps) {
  const { isOpen, itemId, onClose = noop, preview, collection_name } = props;
  const [loading, setLoading] = useState(true);
  const [nftOffers, setNftOffers] = useState<NFTOffer[]>([]);

  const getBestCollectionOfferInEachPlatform = async (
    collectionAddr: string
  ) => {
    const res = await axios.get(
      `https://beta.api.highswap.com/cedar/v1/offer/collection/${collectionAddr}/each`
    );
    const nftOffers = platforms
      .map((platform) => {
        const index = res.data.findIndex(
          (item: any) => item.platform === platform
        );

        if (index !== -1) {
          return res.data[index];
        }

        return platformTemplate(platform);
      })
      .sort((cur, pre) => {
        const curPrice =
          cur?.deductionPrice === '-' ? 0 : Number(cur?.deductionPrice);
        const prePrice =
          pre?.deductionPrice === '-' ? 0 : Number(pre?.deductionPrice);

        return prePrice - curPrice;
      });
    setNftOffers(nftOffers as NFTOffer[]);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getBestCollectionOfferInEachPlatform(itemId.split('_')[0]);
    getBestOfferInEachPlatform(itemId)
      .then((res) => {
        const nftOffers = platforms
          .map((platform) => {
            const index = res.data.findIndex(
              (item) => item.platform === platform
            );

            if (index !== -1) {
              return res.data[index];
            }

            return platformTemplate(platform);
          })
          .sort((cur, pre) => {
            const curPrice =
              cur?.deductionPrice === '-' ? 0 : Number(cur?.deductionPrice);
            const prePrice =
              pre?.deductionPrice === '-' ? 0 : Number(pre?.deductionPrice);

            return prePrice - curPrice;
          });
        setNftOffers(nftOffers as NFTOffer[]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [itemId, isOpen]);

  return (
    <Modal size={'5xl'} isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background={'#000000aa'} />

      <ModalContent
        color="white"
        alignItems={'center'}
        py={8}
        background={'#17171D'}
        borderRadius="20px"
        m={4}
      >
        <ModalCloseButton color="#49494B" m={2} size={'lg'} />
        <Stack
          px={8}
          w={'full'}
          flex={1}
          align={'center'}
          maxHeight={'80%'}
          overflowY="auto"
        >
          <Text
            pt={4}
            fontFamily="Space Grotesk"
            fontWeight="700"
            variant="title_3xl"
          >
            Best Deal Details
          </Text>
          <Text color="#ADADB1">
            (The Best Deal may be different for bulk sales)
          </Text>

          <Flex paddingY="20px">
            <AspectRatio
              rounded={'8px'}
              w="100px"
              overflow={'hidden'}
              ratio={1}
            >
              <>
                {!preview && (
                  <Skeleton
                    fadeDuration={1}
                    startColor="#313136"
                    endColor="#373738"
                  >
                    <Image src={noImage} />
                  </Skeleton>
                )}
                {preview && <Image src={preview || noImage} />}
              </>
            </AspectRatio>

            <Box alignSelf="center" marginLeft="24px">
              <Text fontSize="20px">
                {collection_name} #{itemId?.split('_')[1]}
              </Text>
              <Text color="whiteAlpha.800" fontSize="16px">
                {collection_name}
              </Text>
            </Box>
          </Flex>

          <Box
            fontSize="16px"
            backgroundColor="#1C1C23"
            padding="20px"
            rounded="10px"
            width="full"
          >
            {loading && (
              <Box className="absolute top-0 left-0 flex h-full min-h-[200px] w-full items-center justify-center">
                <Image
                  alignSelf="center"
                  className="animate-loadingData"
                  width="34px"
                  src={loadingIcon}
                />
              </Box>
            )}

            <TableContainer>
              <Table
                size="sm"
                borderBottom="none"
                fontSize="16px"
                border="none"
              >
                <Thead>
                  <Tr>
                    <Th paddingY="9px" border="none" color="#ADADB1">
                      Marketplace
                    </Th>
                    <Th paddingY="9px" border="none" color="#ADADB1">
                      Offer Price
                    </Th>
                    <Th paddingY="9px" border="none" color="#ADADB1">
                      Platform Fee
                    </Th>
                    <Th paddingY="9px" border="none" color="#ADADB1">
                      Creator Fee
                    </Th>
                    <Th paddingY="9px" border="none" color="#ADADB1">
                      Net Income
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {nftOffers.map((offer, index) => {
                    return (
                      <Tr color="#ffffff" key={index}>
                        <Td paddingY="9px" border="none">
                          <Flex>
                            <Box alignSelf="center">
                              <PlatformIcon size={24} type={offer.platform} />
                            </Box>
                            <Text paddingLeft="10px" alignSelf="center">
                              {offer.platform}
                            </Text>
                          </Flex>
                        </Td>
                        <Td paddingY="9px" border="none">
                          {offer.deductionPrice !== '-'
                            ? `${formatPrice(offer.price)}  WETH`
                            : '-'}
                        </Td>
                        <Td paddingY="9px" border="none">
                          {toPercent(offer.platformFee)}
                        </Td>
                        <Td paddingY="9px" border="none">
                          {toPercent(offer.creatorFee)}
                        </Td>
                        <Td paddingY="9px" border="none">
                          {offer.deductionPrice !== '-'
                            ? `${formatPrice(offer.deductionPrice)} WETH`
                            : '-'}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </ModalContent>
    </Modal>
  );
}

function noop() {
  //
}
