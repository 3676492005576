import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Filter } from 'redux_store/filter/model';
import { AllActionsOf } from 'redux_store/utils/Actions';

export const initialState: Filter = {
  filterOffer: false,
  collectionFilter: [],
  platformFilter: [],
  minprice: 0,
  maxprice: 0,
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    getUserCollections() {},
    updateCollectionFilter(state, action: PayloadAction<string[]>) {
      state.collectionFilter = action.payload;
    },
    updatePlatformFilter(state, action: PayloadAction<string[]>) {
      state.platformFilter = action.payload;
    },
    updateOfferFilter(state, action: PayloadAction<boolean>) {
      state.filterOffer = action.payload;
    },
    updateMin(state, action: PayloadAction<number>) {
      state.minprice = action.payload;
    },
    updateMax(state, action: PayloadAction<number>) {
      state.maxprice = action.payload;
    },
    getUserCollectionsSuccess(state, action) {
      state.collectionFilter = action.payload;
    },
    clearFilter(state) {
      state.filterOffer = initialState.filterOffer;
      state.collectionFilter = initialState.collectionFilter;
      state.minprice = initialState.minprice;
      state.maxprice = initialState.maxprice;
    },
  }
});

const { actions, reducer } = slice;
export type CollectionActionsType = AllActionsOf<typeof actions>;
export { actions as filterActions, reducer as filterReducers };
