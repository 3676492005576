import axios from 'axios';
import { httpClient } from 'services/api/httpClient';
import { Nft, PurchaseQuote, PurchaseResponse } from 'services/api/nft/model';
import { getEtherscanApiKey } from 'services/api/util';

export async function getFeaturedNfts(): Promise<Nft[]> {
  const response = await httpClient.sendRequest({
    url: 'nft/list',
    method: 'GET'
  });
  return response.data?.slice(0, 10) ?? [];
}

export async function getNftDetail(
  tokenId: string,
  slug: string
): Promise<Nft> {
  const response = await httpClient.sendRequest({
    url: `contract/${slug}/nft/${tokenId}`,
    method: 'GET'
  });
  return response.data;
}
export async function searchNft(keyword: string): Promise<Nft[]> {
  const response = await httpClient.sendRequest({
    url: `nft/search?keyword=${keyword}`,
    method: 'GET'
  });
  return response.data;
}

export async function buyNft(
  tokenId: string,
  chain_type: number
): Promise<PurchaseResponse> {
  const response = await httpClient.sendRequest({
    url: `order/buy/${tokenId}`,
    method: 'POST',
    data: { chain_type: chain_type }
  });
  return response.data;
}
export async function getPurchaseQuote(
  slug: string,
  tokenId: number
): Promise<PurchaseQuote> {
  const response = await httpClient.sendRequest({
    url: `contract/${slug}/nft/${tokenId}/quote`,
    method: 'GET'
  });

  return response.data;
}

export async function getPurchaseOrder(
  orderId: number
): Promise<PurchaseResponse> {
  const response = await httpClient.sendRequest({
    url: `order/${orderId}/buy`,
    method: 'GET'
  });
  console.log(response);
  return response.data;
}

export async function getOrderDetail(
  orderId: number
): Promise<PurchaseResponse> {
  const response = await httpClient.sendRequest({
    url: `order/${orderId}`,
    method: 'GET'
  });
  console.log(response);
  return response.data;
}

export async function confirmTransaction(
  hash: string
): Promise<{ status: string; message: string; result: { status: string } }> {
  const response = await axios(
    `https://api.etherscan.io/api?module=transaction&action=gettxreceiptstatus&txhash=${hash}&apikey=${getEtherscanApiKey()}`
  );
  return response.data;
}

export async function likeNft(id: number): Promise<string> {
  const response = await httpClient.sendRequest({
    url: `nft/like`,
    method: 'POST',
    data: {
      nft_id: id
    }
  });
  return response.data;
}

export async function dislikeNft(id: number): Promise<string> {
  const response = await httpClient.sendRequest({
    url: `nft/dislike`,
    method: 'POST',
    data: {
      nft_id: id
    }
  });
  return response.data;
}

export async function listNft(
  orderId: number,
  end_at: number,
  price: number,
  market: number
): Promise<string> {
  const response = await httpClient.sendRequest({
    url: `order/list/${orderId}`,
    method: 'POST',
    data: {
      end_at,
      price,
      market
    }
  });
  return response.data;
}

export async function repayOrder(
  orderId: number,
  amount: number
): Promise<PurchaseResponse> {
  const response = await httpClient.sendRequest({
    url: `order/repay/${orderId}`,
    method: 'POST',
    data: {
      amount: amount
    }
  });
  return response.data;
}

export async function withdrawOrder(
  orderId: number,
  withdraw_address: string
): Promise<PurchaseResponse> {
  const response = await httpClient.sendRequest({
    url: `order/withdraw/${orderId}`,
    method: 'POST',
    data: {
      withdraw_address: withdraw_address
    }
  });
  return response.data;
}

export async function getExploreNft(): Promise<Nft[]> {
  const response = await httpClient.sendRequest({
    url: 'nft/list',
    method: 'GET'
  });
  return response.data;
}
