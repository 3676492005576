import styled from '@emotion/styled';
import React from 'react';

interface FlowBoxProps {
  w?: string;
  h?: string;
  border?: string;
}

const defaultProps = {
  w: '200px',
  h: '130px',
  border: '5px'
};

const FlowBoxRoot = styled.div<FlowBoxProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.w};
  height: ${(p) => p.h};
  border-radius: 10px;
  flex-shrink: 0;
  overflow: hidden;
  // fix safari border-radius incompatible with overflow hidden
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  padding: 5px;
  /* border: 1px solid #00000055; */

  & > * {
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    width: calc(100% - ${(p) => p.border});
    height: calc(100% - ${(p) => p.border});
  }

  &::after {
    position: absolute;
    content: '';
    border-radius: 12px;
    /* top: calc(-1 * ${(p) => p.border});
    left: calc(-1 * ${(p) => p.border});
    width: calc(100% + ${(p) => p.border} * 2);
    height: calc(100% + ${(p) => p.border} * 2); */
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    /* background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    ); */
    /* background: linear-gradient(
      90deg,
      rgba(131, 58, 180, 1) 0%,
      rgba(253, 29, 29, 1) 19%,
      rgba(253, 86, 44, 1) 31%,
      rgba(252, 176, 69, 1) 51%,
      rgba(243, 252, 69, 1) 66%,
      rgba(111, 252, 69, 1) 88%
    ); */
    background: linear-gradient(
      90deg,
      rgba(203, 131, 184, 1) 0%,
      rgba(243, 187, 189, 1) 8%,
      rgba(224, 188, 200, 1) 15%,
      rgba(195, 198, 219, 1) 23%,
      rgba(151, 217, 238, 1) 31%,
      rgba(112, 197, 240, 1) 40%,
      rgba(112, 145, 240, 1) 49%,
      rgba(114, 112, 240, 1) 58%,
      rgba(153, 112, 240, 1) 68%,
      rgba(200, 112, 240, 1) 79%,
      rgba(240, 112, 190, 1) 87%,
      rgba(240, 112, 112, 1) 95%
    );
    /* box-shadow: 0 1px 0 0 rgb(225 131 194 / 25%),
      0 -1px 0 0 rgb(165 181 222 / 25%), 1px 0 0 0 rgb(225 131 194 / 25%),
      -1px 0 0 0 rgb(165 181 222 / 25%), 1px -1px 0 0 rgb(195 156 208 / 50%),
      -1px 1px 0 0 rgb(195 156 208 / 50%), 1px 1px 0 0 rgb(255 105 180 / 75%),
      -1px -1px 0 0 rgb(135 206 235 / 75%); */
    background-size: 100% 100%;
    background-position: 0 50%;
    /* animation: moveGradient 4s alternate infinite; */
    animation: rotate-360 4s linear infinite;
  }

  @keyframes moveGradient {
    50% {
      background-position: 100% 50%;
    }
  }

  @keyframes rotate-360 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FlowBox = (props: any) => {
  return <FlowBoxRoot {...props}>{props.children}</FlowBoxRoot>;
};

FlowBox.defaultProps = defaultProps;

export default FlowBox;
