const ID_TOKEN_KEY = 'jwt-token';

export const getToken = () => window.localStorage.getItem(ID_TOKEN_KEY);

export const saveToken = (token: string) =>
  window.localStorage.setItem(ID_TOKEN_KEY, token);

export const destroyToken = () => window.localStorage.removeItem(ID_TOKEN_KEY);

const getReservoirApiByEnv = () => {
  if (process.env.REACT_APP_NETWORK === 'rinkeby') {
    return 'https://api-rinkeby.reservoir.tools';
  }
  return 'https://api.reservoir.tools';
};

export const reservoirApi = getReservoirApiByEnv();

export const getReservoirsApiKey = () => {
  const reservoirKeys = JSON.parse(process.env.REACT_APP_RESERVOIR_API_KEYS || '[]');
  return reservoirKeys[Math.floor(Math.random() * reservoirKeys.length)];
};

export const getCenterApiKey = () => {
  const centerApiKeys = JSON.parse(process.env.REACT_APP_CENTER_API_KEYS || '[]');
  return centerApiKeys[Math.floor(Math.random() * centerApiKeys.length)];
};

export const getEtherscanApiKey = () => {
  const ethersacanApiKeys = JSON.parse(process.env.REACT_APP_ETHERSCAN_API_KEYS || '[]');
  return ethersacanApiKeys[Math.floor(Math.random() * ethersacanApiKeys.length)];
};
