import { useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getFeaturedNfts } from 'services/api/nft';
import { Nft } from 'services/api/nft/model';

import FeaturedGrid from '../elements/home-highswap/FeaturedGrid';
import LandingPageIntro from '../elements/home-highswap/LandingPageIntro';

export default function HomeBackup() {
  const [featuredNfts, setFeaturedNfts] = useState<Nft[]>([]);
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    window.scrollTo(0, 0);

    getTrendings();
  }, []);
  const getTrendings = async () => {
    try {
      const data = await getFeaturedNfts();
      setFeaturedNfts(data);
    } catch (e) {}
  };

  const root = React.useRef<HTMLDivElement>(null);
  const [fade, setFade] = React.useState(false);
  const [scrollY, setScrollY] = React.useState(0);

  const transitionEffect = '0.35s cubic-bezier(0.62, 0.02, 0.34, 1) 0.15s';

  React.useEffect(() => {
    function handleScroll() {
      const position = root.current ? root.current.scrollTop : 0;
      setScrollY(position);

      if (position > 260) {
        setFade(true);
      }

      if (position < 260) {
        setFade(false);
      }
    }

    root.current?.addEventListener('scroll', handleScroll);
    return () => root.current?.removeEventListener('scroll', handleScroll);
  }, [scrollY]);

  return (
    <main>
      <LandingPageIntro />
      <FeaturedGrid />
    </main>
  );
}
