import { Platform } from 'services/api/offer/model';

export interface SellingCartItem {
  itemId: string;
  hash: string;
  preview: string;
  collection_name: string;
  price: string;
  platform: Platform;
  creatorFee: number;
  platformFee: number;
  deductionPrice: string;
  type:string;
  protocolAddress:string;
  maker:string;
}

export enum TransactionStatus {
  PENDING = '',
  COMPLETED = '1',
  FAILED = '0'
}

export interface NftInTransaction {
  hash: string;
  status: TransactionStatus;
  title: string;
  platform?: Platform;
}

export interface SellingCartState {
  isOpenSellCard: boolean;
  trading: boolean;
  sellingCartItems: SellingCartItem[];
  nftsInTransaction: NftInTransaction[];
}
