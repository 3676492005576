export interface NFTOfferResponse {
  [key: string]: {
    nfts: NftOffers[];
    offers: NFTOffer[];
  };
}

export interface NftOffers {
  [key: string]: NFTOffer[];
}

export interface NFTOffer {
  name?: string;
  contractParams: ContractParams;
  creatorFee: number;
  expirationTime: 0;
  id: string;
  hash: string;
  itemId: string;
  maker: string;
  platform: Platform;
  platformFee: number;
  price: string;
  deductionPrice: string;
  type: string;
  usable: boolean;
  protocolAddress: string;
}

export enum Platform {
  OPENSEA = 'OPENSEA',
  SUDOSWAP = 'SUDOSWAP',
  LOOKSRARE = 'LOOKSRARE',
  X2Y2 = 'X2Y2',
  NFTX = 'NFTX'
}

export const platforms = [
  Platform.OPENSEA,
  Platform.LOOKSRARE,
  Platform.X2Y2,
  Platform.SUDOSWAP,
  Platform.NFTX
];

export interface ContractParams {
  considerationCriteria: Array<{
    identifier: string;
    proof: [];
  }>;
  order: {
    parameters: {
      offerer: string;
      offer: Array<{
        itemType: number;
        token: string;
        identifierOrCriteria: string;
        startAmount: string;
        endAmount: string;
      }>;
      consideration: Array<{
        itemType: number;
        token: string;
        identifierOrCriteria: string;
        startAmount: string;
        endAmount: string;
        recipient: string;
      }>;
      startTime: string;
      endTime: string;
      orderType: number;
      zone: string;
      zoneHash: string;
      salt: string;
      conduitKey: string;
      totalOriginalConsiderationItems: number;
      counter: number;
    };
    signature: string;
  };
}
