import {
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  InfoOutlineIcon,
  SpinnerIcon
} from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  useInterval
} from '@chakra-ui/react';
import { ExternalProvider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { queryClient } from 'App';
import cn from 'classnames';
import { ethers, utils } from 'ethers';
import { LooksRare, ChainId } from "@looksrare/sdk-v2";

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux_store/rootReducer';
import {
  SellingCartItem,
  TransactionStatus
} from 'redux_store/sellingCart/model';
import { sellingCartActions } from 'redux_store/sellingCart/slice';
import { confirmTransaction } from 'services/api/nft';
import { getNFTOffersInCart, refreshOffers } from 'services/api/offer';
import { NFTOffer } from 'services/api/offer/model';
import { handleMetamaskError } from 'utils';
import { handleContractParams } from 'utils/handleContractParams';
import { OpenSeaSDK, Chain } from "opensea-js";

import loadingIcon from '../../../assets/icons/loadingIcon.svg';
import WETHIcon from '../../../assets/icons/WETH.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import { MarketLogo } from '../marketLogo';
import { useCustomToast } from '../toast/useToast';
import { CartItem } from './cartItem';
import { EmptyCart } from './emptyCart';

interface SellingCartProps {
  classname?: string;
  onRefresh: () => void;
}

export const SellingCart = (props: SellingCartProps) => {
  const { classname, onRefresh } = props;
  const dispatch = useDispatch();
  const toast = useCustomToast();
  const { library, account } = useWeb3React();
  const { sellingCartItems, trading, nftsInTransaction } = useSelector(
    (s: RootState) => s.sellingCart
  );
  const { ETHPrice } = useSelector((s: RootState) => s.wallet);
  const [sellingCartNFTs, setSellingCartNFTs] = useState<NFTOffer[]>([]);
  const [fetchingPrice, setFetchingPrice] = useState(true);
  const [ifChanged, setIfChanged] = useState(false);
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const [sellingCartVisible, setSellingCartVisible] = useState(isDesktop);

  useEffect(() => {
    setSellingCartVisible(isDesktop);
  }, [isDesktop]);

  const { isFetching, refetch } = useQuery(
    ['getNFTOffersInCart', sellingCartItems],
    async () => {
      if (sellingCartItems.length === 0) return [];
      setFetchingPrice(true);
      console.log('sellingCartItems ', sellingCartItems);
      setFetchingPrice(false);
      return;
    },
    {
      initialData: () => {
        return queryClient.getQueryData('getNFTOffersInCart');
      },
      refetchOnWindowFocus: false,
      retry: 20,
      onSuccess: (data) => {
        setIfChanged(false);
      }
    }
  );

  const fulfillOffer = async (offerData : any, itemid: string, protocolAddress = '0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC', hash: string) => {
    try {
      const sections = itemid.split("_");
      const collectionAddress = sections[0];
      const data = offerData;
      const web3Provider = new ethers.providers.Web3Provider(library.provider);

      const signer = web3Provider.getSigner();
      const address = await signer.getAddress();
      const abi = require('./abi.json');
      const nftabi = require('./nftabi.json');
      const contract = new ethers.Contract(protocolAddress, abi, signer);
      const nftContract = new ethers.Contract(collectionAddress, nftabi, signer);
      let functionName = '';
      let params: any = {};
      const contractFunction = data.fulfillment_data.transaction.function;
      if (contractFunction.includes("matchAdvancedOrders")) {
        functionName = 'matchAdvancedOrders';
        params = data.fulfillment_data.transaction.input_data;
      } else {
        functionName = 'fulfillBasicOrder_efficient_6GL6yc';
        params = data.fulfillment_data.transaction.input_data.parameters;
      }
      const isApproved = await nftContract.isApprovedForAll(address, "0x1E0049783F008A0085193E00003D00cd54003c71");
      console.log(' isApproved ', isApproved)
      if (!isApproved) {
        dispatch(sellingCartActions.updateTradingStatus(true));
        await nftContract.setApprovalForAll("0x1E0049783F008A0085193E00003D00cd54003c71", true);
      }

      try {
        if (contractFunction.includes("matchAdvancedOrders")) {
          const tx = await contract.matchAdvancedOrders(
            params.orders,
            params.criteriaResolvers,
            params.fulfillments,
            params.recipient,
            { from: address, gasLimit: ethers.utils.hexlify(300000) }
          );
          dispatch(sellingCartActions.updateTradingStatus(false));
  
        } else {
          const tx = await contract.fulfillBasicOrder_efficient_6GL6yc(
            params,
            { from: address, gasLimit: ethers.utils.hexlify(200000) }
          );
          dispatch(sellingCartActions.updateTradingStatus(false));
        }
      } catch (error) {
        dispatch(sellingCartActions.updateTradingStatus(false));
        const errorMsg = handleMetamaskError(error as Error);

        toast({
          title: 'Something went wrong!',
          description: errorMsg,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }


      
      // console.log(tx)
    } catch (error) {
      dispatch(sellingCartActions.updateTradingStatus(false));
      const errorMsg = handleMetamaskError(error as Error);

      toast({
        title: 'Something went wrong!',
        description: errorMsg,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  // const fulfillOffer = async (offerData : any, type: string, protocolAddress = '0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC') => {
  //   try {
  //     const data = offerData;
  //     const web3Provider = new ethers.providers.Web3Provider(library.provider);
  //     const signer = web3Provider.getSigner();
  //     const address = await signer.getAddress();
  //     const abi = require('./abi.json');
  //     const contract = new ethers.Contract(protocolAddress, abi, signer);
  //     let functionName = '';
  //     let params: any = {};
  //     const contractFunction = data.fulfillment_data.transaction.function;
  //     if (contractFunction.includes("matchAdvancedOrders")) {
  //       functionName = 'matchAdvancedOrders';
  //       params = data.fulfillment_data.transaction.input_data;
  //     } else {
  //       functionName = 'fulfillBasicOrder_efficient_6GL6yc';
  //       params = data.fulfillment_data.transaction.input_data.parameters;
  //     }
      
  //     // const gasPrice = await web3Provider.getGasPrice();

  //     // console.log('gasPrice ', ethers.utils.hexlify(gasPrice))

  //     try {
  //       if (contractFunction.includes("matchAdvancedOrders")) {
  //         const tx = await contract.matchAdvancedOrders(
  //           params.orders,
  //           params.criteriaResolvers,
  //           params.fulfillments,
  //           params.recipient,
  //           { from: address, gasLimit: ethers.utils.hexlify(200000) }
  //         );
  //         dispatch(sellingCartActions.updateTradingStatus(false));
  
  //       } else {
  //         const tx = await contract.fulfillBasicOrder_efficient_6GL6yc(
  //           params,
  //           { from: address, gasLimit: ethers.utils.hexlify(200000) }
  //         );
  //         dispatch(sellingCartActions.updateTradingStatus(false));
  //       }
  //     } catch (error) {
  //       dispatch(sellingCartActions.updateTradingStatus(false));
  //       const errorMsg = handleMetamaskError(error as Error);

  //       toast({
  //         title: 'Something went wrong!',
  //         description: errorMsg,
  //         status: 'error',
  //         duration: 9000,
  //         isClosable: true
  //       });
  //     }


      
  //     // console.log(tx)
  //   } catch (error) {
  //     dispatch(sellingCartActions.updateTradingStatus(false));
  //     const errorMsg = handleMetamaskError(error as Error);

  //     toast({
  //       title: 'Something went wrong!',
  //       description: errorMsg,
  //       status: 'error',
  //       duration: 9000,
  //       isClosable: true
  //     });
  //   }
  // };



  const fulfillLooksrare = async (item : any) => {
    const web3Provider = new ethers.providers.Web3Provider(library.provider);
    const signer = web3Provider.getSigner();
    const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/84842078b09946638c03157f83405213');

    const lr = new LooksRare(ChainId.MAINNET, provider, signer);
    
    const address = await signer.getAddress();
    // const abi = require('./lrabi.json');

    // const contractAddress = '0x0000000000E655fAe4d56241588680F86E3b2377';
    // const contract = new ethers.Contract(contractAddress, abi, signer);
    
    const [collectionAddress, itemId] = item.itemId.split("_");
    const jsonParameters = JSON.parse(item.protocolAddress);
    
    const maker = {
      quoteType: parseInt(jsonParameters.quoteType, 10),
      globalNonce: jsonParameters.globalNonce,
      subsetNonce: jsonParameters.subsetNonce,
      orderNonce: jsonParameters.orderNonce,
      strategyId: parseInt(jsonParameters.strategyId, 10),
      collectionType: parseInt(item.type, 10),
      collection: collectionAddress,
      currency: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      signer: item.maker,
      startTime: jsonParameters.startTime,
      endTime: jsonParameters.endTime,
      price: item.price,
      itemIds: [itemId],
      amounts: jsonParameters.amounts,
      additionalParameters: jsonParameters.additionalParameters,
    }

    // The recipient address is optional, if you don't provide it will use your signer address
    const takerOrder = lr.createTaker(maker, address);

    
    const { isCollectionApproved, isTransferManagerApproved } = await lr.createMakerAsk({
      collection: collectionAddress, // Collection address
      collectionType: parseInt(item.type, 10),
      strategyId: parseInt(jsonParameters.strategyId, 10),
      subsetNonce: 0, 
      orderNonce: jsonParameters.orderNonce, 
      endTime: jsonParameters.endTime, // If use a timestamp in ms, the function will revert
      price: item.price, // Be careful to use a price in wei, this example is for 1 ETH
      itemIds: [itemId], // Token id of the NFT(s), add several ids to create a bundle
      amounts: jsonParameters.amounts, // Use it for listing multiple ERC-1155 (Optional, Default to [1])
      startTime: Math.floor(Date.now() / 1000), // Use it to create an order that will be valid in the future (Optional, Default to now)
    });
    // console.log('isCollectionApproved ', isCollectionApproved)
    // console.log(isTransferManagerApproved)
    // Grant the TransferManager the right the transfer assets on behalf od the LooksRareProtocol
    if (!isTransferManagerApproved) {
      const tx = await lr.grantTransferManagerApproval().call();
      await tx.wait();
    }

    // Approve the collection items to be transferred by the TransferManager
    if (!isCollectionApproved) {
      const tx = await lr.approveAllCollectionItems(maker.collection);
      await tx.wait();
    }

    const signature = item.hash
    const merkleTree = {
      root: jsonParameters?.merkleRoot || '0x0000000000000000000000000000000000000000000000000000000000000000',
      proof: jsonParameters?.merkleProof || []
    };
    // Grant the TransferManager the right the transfer assets on behalf od the LooksRareProtocol
    
    const executeTakerAsk = async () => {
      try {
        const { estimateGas, } = lr.executeOrder(maker, takerOrder, signature, undefined, undefined);
        const gas = await estimateGas();
        const { call } = lr.executeOrder(maker, takerOrder, signature, undefined, undefined,{gasLimit: gas})
        const tx = await call();
        const receipt = await tx.wait();
        console.log('receipt ', receipt)
        dispatch(sellingCartActions.updateTradingStatus(false));
      } catch (error) {
        dispatch(sellingCartActions.updateTradingStatus(false));
        const errorMsg = handleMetamaskError(error as Error);

        toast({
          title: 'Something went wrong!',
          description: errorMsg,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    };

    executeTakerAsk();

  } 

  const totalPrice = useMemo(() => {
    const itemsPrice = sellingCartItems.map((item) =>
      Number(item.price)
    );
    const price = itemsPrice.reduce((pre, current) => pre + current, 0);
    if (price === 0) return price.toFixed(2);

    const priceInt = price.toString();
    return priceInt === 'NaN' ? '0.00' : Number(utils.formatEther(priceInt));
  }, [sellingCartNFTs, sellingCartItems]);

  const clearAll = useCallback(() => {
    dispatch(sellingCartActions.update([]));
    dispatch(sellingCartActions.updateTradingStatus(false));
    dispatch(sellingCartActions.updateTransaction([]));
  }, [dispatch]);

  const deleteNFT = useCallback(
    (tokenId: string) => {
      dispatch(sellingCartActions.delete(tokenId));
    },
    [dispatch]
  );

  useEffect(() => {
    const handleAccountsChanged = () => {
      clearAll();
    };

    library?.provider.on('accountsChanged', handleAccountsChanged);

    return () => {
      library?.provider.removeListener(
        'accountsChanged',
        handleAccountsChanged
      );
    };
  }, [clearAll, dispatch, library]);

  useInterval(() => {
    if (sellingStatus !== 'pending') return;

    nftsInTransaction.forEach(async (tx) => {
      // replace waitForTransaction
      const res = await confirmTransaction(tx.hash);
      dispatch(
        sellingCartActions.updateTransactionStatus({
          ...tx,
          hash: tx.hash,
          status: res.result.status as TransactionStatus
        })
      );
    });
  }, 2000);

  const sellingStatus = useMemo(() => {
    if (nftsInTransaction.length === 0) return 'notStart';

    const ifPending = nftsInTransaction
      .map((tx) => {
        return tx.status === '';
      })
      .includes(true);
    return ifPending ? 'pending' : 'finished';
  }, [nftsInTransaction]);

  useEffect(() => {
    if (sellingStatus === 'finished') {
      onRefresh();
    }
  }, [sellingStatus]);

  return (
    <>
      {isDesktop && (
        <Text
          paddingBottom="10px"
          paddingX="10px"
          color="#ADADB1"
          fontSize="20px"
          fontWeight={'bold'}
          whiteSpace="nowrap"
        >
          HIGHSWAP Optimization Applied
          <Icon className="mx-[10px]" fontSize="14px" as={InfoOutlineIcon} />
        </Text>
      )}

      <Box
        backgroundColor={['#212124', '#212124', 'unset']}
        borderWidth={['1px', '1px', '0']}
        borderRadius={['12px', '12px', '0']}
        borderColor={['#404045', '#404045', 'unset']}
        className={cn(classname, 'z-10 mt-[0!important] w-full')}
      >
        <Box
          hidden={sellingStatus !== 'notStart'}
          position="relative"
          backgroundColor={['unset', 'unset', '#212124']}
          borderWidth={['0', '0', '1px']}
          borderRadius={['0', '0', '12px']}
          borderColor={['unset', 'unset', '#404045']}
        >
          <Flex justifyContent="'space-between'">
            <Text
              fontSize={'xl'}
              color={'#DEDEE6'}
              fontWeight={'bold'}
              paddingX={['0', '0', '16px']}
              paddingLeft="16px"
              paddingRight="8px"
              className="flex py-[12px]"
              flexGrow={isDesktop ? 1 : undefined}
            >
              <h1>Selling Cart</h1>

              <span className="ml-2 flex h-[24px] w-[24px] items-center justify-center self-center rounded-full border-[1px] border-[#404045] text-[10px] text-[#ADADB1]">
                {sellingCartItems.length}
              </span>
            </Text>

            <Text
              paddingX={['0', '0', '16px']}
              userSelect="none"
              cursor="pointer"
              alignSelf="center"
              onClick={clearAll}
              color="#ADADB1"
              flexGrow={!isDesktop ? 1 : undefined}
            >
              Clear All
            </Text>

            <Icon
              // hidden={isDesktop || !sellingCartVisible}
              alignSelf="center"
              fontSize={30}
              as={ChevronDownIcon}
              onClick={() => setSellingCartVisible(false)}
            ></Icon>
          </Flex>

          {sellingCartVisible && sellingCartItems.length === 0 && <EmptyCart />}

          {sellingCartVisible &&
            sellingCartItems.map((item) => {
              return (
                <CartItem
                  ETHPrice={ETHPrice}
                  onDelete={deleteNFT}
                  key={item.itemId}
                  tokenId={item.itemId}
                  collectionName={''}
                  platform={item.platform}
                  creatorFee={item.creatorFee}
                  platformFee={item.platformFee}
                  price={item.price}
                  deductionPrice={item.price}
                />
              );
            })}

          <Divider display={['none', 'none', 'block']} />

          <Grid
            className="py-[12px] px-[16px]"
            h="full"
            templateRows="repeat(2, 2fr)"
            templateColumns={[
              'repeat(13, 1fr)',
              'repeat(13, 1fr)',
              'repeat(8, 1fr)'
            ]}
            gap={2}
          >
            <GridItem
              className="flex flex-col justify-center"
              rowSpan={2}
              colSpan={2}
            >
              <Text
                fontSize={['12px', '12px', '16px']}
                fontWeight="bold"
                color="#DEDEE6"
                whiteSpace="nowrap"
              >
                You Earn
              </Text>
            </GridItem>

            <GridItem
              className="flex flex-col justify-center"
              rowSpan={2}
              colSpan={sellingCartVisible ? 10 : 6}
              fontSize={['12px', '12px', '16px']}
            >
              <Flex justifyContent="flex-end">
                <Image alignSelf="center" width="18px" src={WETHIcon}></Image>
                <Text
                  alignSelf="center"
                  color="rgba(255, 255, 255, 0.8)"
                  marginRight="4px"
                >
                  WETH
                </Text>
                <Text
                  alignSelf="center"
                  fontSize="18px"
                  fontWeight="700"
                  color="#FFFFFF"
                >
                  {Number(totalPrice).toFixed(4)}
                </Text>
              </Flex>

              <Text textAlign="right" fontSize="14px" color="#FFFFFF">
                ${(ETHPrice * Number(totalPrice)).toFixed(2)}
              </Text>
            </GridItem>

            <GridItem
              className="flex flex-1 flex-col justify-center md:hidden"
              hidden={sellingCartVisible}
              rowSpan={2}
              colSpan={5}
            >
              <PrimaryButton
                onClick={() => setSellingCartVisible(true)}
                className="ml-1 h-full w-full"
                title={'View Cart'}
              />
            </GridItem>
          </Grid>

          <Flex
            hidden={!trading}
            backgroundColor="rgba(23, 23, 29, 0.8)"
            justifyContent="center"
            position="absolute"
            left="0"
            top="0"
            width="full"
            height="full"
            borderRadius="12px"
          >
            <Box alignSelf="center">
              <Image
                className="m-auto mb-2 animate-loadingData"
                width="64px"
                src={loadingIcon}
              />
              <Text color="#DEDEE6" fontSize="14px">
                Optimizing prices...
              </Text>
            </Box>
          </Flex>

          <Flex
            hidden={!isFetching || sellingCartItems.length > 0}
            backgroundColor="rgba(23, 23, 29, 0.8)"
            justifyContent="center"
            position="absolute"
            left="0"
            top="0"
            width="full"
            height="full"
            borderRadius="12px"
          >
            <Box alignSelf="center">
              <Image
                className="m-auto mb-2 animate-loadingData"
                width="44px"
                src={loadingIcon}
              />
            </Box>
          </Flex>
        </Box>

        <Box
          hidden={sellingStatus === 'notStart'}
          backgroundColor={['unset', 'unset', '#212124']}
          borderWidth={['0', '0', '1px']}
          borderRadius={['0', '0', '12px']}
          borderColor={['unset', 'unset', '#404045']}
          paddingX="16px"
          paddingBottom="25px"
          position="relative"
        >
          <Text
            fontSize={'xl'}
            color={'#DEDEE6'}
            fontWeight={'bold'}
            className="flex py-[12px]"
          >
            In progress
          </Text>
          <Text
            fontSize="14px"
            color={'#FFFFFF'}
            fontWeight={'bold'}
            className="flex pt-[12px]"
          >
            Initializing wallet...
          </Text>
          <Text
            fontSize="14px"
            color={'#ADADB1'}
            fontWeight={'bold'}
            className="flex pt-[5px] pb-[25px]"
          >
            Processing transactions (
            {nftsInTransaction.filter((tx) => tx.status !== '').length}/
            {nftsInTransaction.length})
          </Text>

          {nftsInTransaction.map((tx) => {
            return (
              <Flex key={tx.hash} paddingBottom="10px">
                {tx.status === TransactionStatus.PENDING && (
                  <Icon
                    alignSelf="center"
                    className="animate-spin"
                    as={SpinnerIcon}
                  />
                )}
                {tx.status === TransactionStatus.COMPLETED && (
                  <Icon
                    border="2px"
                    borderRadius="full"
                    alignSelf="center"
                    as={CheckIcon}
                    padding="2px"
                    color="#1C9E29"
                  />
                )}
                {tx.status === TransactionStatus.FAILED && (
                  <Icon
                    border="2px"
                    borderRadius="full"
                    alignSelf="center"
                    as={CloseIcon}
                    padding="2px"
                    color="red.500"
                  />
                )}

                <Text color="#FFFFFF" alignSelf="center" paddingX="12px">
                  {tx.title}
                </Text>

                <MarketLogo market={tx.platform} size={20} />
              </Flex>
            );
          })}

          <Text
            fontSize="14px"
            color={'rgba(255, 255, 255, 0.5)'}
            fontWeight={'bold'}
            className="flex pt-[12px]"
          >
            {sellingStatus === 'finished' ? 'Completed' : 'Finalizing'}
          </Text>
        </Box>

        {sellingCartVisible && sellingCartItems.length > 0 && (
          <Flex
            paddingX={['8px', '8px', '0']}
            flexDirection={['column-reverse', 'column']}
          >
            <Text
              color="#ADADB1"
              className="my-[12px]"
              fontSize={['10px', '12px']}
            >
              <Icon w={3} h={3} as={InfoOutlineIcon} marginRight="10px" />
              By clicking "Sell NFTs Instantly", you acknowledge that this NFTs
              will be sold on the marketplaces where best deals are found.
            </Text>

            {sellingStatus === 'notStart' && (
              <PrimaryButton
                isLoading={trading}
                disabled={sellingCartItems.length === 0 || fetchingPrice}
                title={'Sell NFTs Instantly'}
                onClick={() => {
                  if (trading) return;
                  if (sellingCartItems.length > 0) {
                    acceptOffer(sellingCartNFTs);
                  }
                }}
              />
            )}

            {sellingStatus === 'pending' && (
              <PrimaryButton
                title={'Sell NFTs Instantly'}
                isLoading={trading}
                disabled={sellingCartNFTs.length === 0 && true}
              />
            )}

            {sellingStatus === 'finished' && (
              <PrimaryButton
                title={'Completed'}
                onClick={() => {
                  dispatch(sellingCartActions.updateTransaction([]));
                  dispatch(sellingCartActions.updateTradingStatus(false));
                  dispatch(sellingCartActions.update([]));
                }}
              />
            )}

            {ifChanged && (
              <PrimaryButton
                className="mt-[16px]"
                bgColor="#70BEFF"
                title={'Refresh the Cart'}
                onClick={() => {
                  refetch();
                }}
              />
            )}
          </Flex>
        )}
      </Box>
    </>
  );

  async function acceptOffer(sellingCartNFTs: NFTOffer[]) {
    try {
      console.log('sellingCartNFTs ', sellingCartNFTs)
      dispatch(sellingCartActions.updateTradingStatus(true));


      for (const item of sellingCartItems) {
        if (item.platform === 'OPENSEA') {
          const data = await getNFTOffersInCart(item.hash, account!, item.itemId, item.protocolAddress);
          console.log('itm ', item)
          fulfillOffer(data.result.data, item.itemId, item.protocolAddress, item.hash);
        } else {
          fulfillLooksrare(item)
        }
      }
      // if (JSON.stringify(sellingCartNFTs) !== JSON.stringify(data)) {
      //   setIfChanged(true);
      //   throw new Error('Offers has changed! Please refresh the cart.');
      // }

    } catch (e) {
      dispatch(sellingCartActions.updateTradingStatus(false));
      const errorMsg = handleMetamaskError(e as Error);

      toast({
        title: 'Something went wrong!',
        description: errorMsg,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }
};
